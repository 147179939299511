import { Button } from '@components/button';
import { FieldInput } from '@components/field-input';
import { Formux } from '@components/formux';

import { useRouter } from '@features/router/useRouter';
import { showSuccessToast } from '@features/toast';

import { useAuth } from '@hooks/useAuth';

import { AuthContainer } from '@pages/@common/auth-container';
import { AuthIconUser } from '@pages/@common/auth-icon-user';
import { OutlinedBox } from '@pages/@common/outlined-box';

export const SingIn = () => {
  const { pushRoute } = useRouter();

  const { authSignIn } = useAuth();

  return (
    <AuthContainer
      formContent={
        <>
          <OutlinedBox>
            <div className="flex flex-col items-center gap-2">
              <AuthIconUser />
              <p className="text-2xl text-black font-normal">Welcome Back!</p>
              <p className="text-sm">Please login to continue</p>
            </div>

            <Formux
              value={{ username: '', password: '' }}
              validate={[
                {
                  field: 'username',
                  type: 'required',
                  message: 'Email is required'
                },
                {
                  field: 'username',
                  type: 'email',
                  message: 'Username must be a valid email'
                },
                {
                  field: 'password',
                  type: 'required',
                  message: 'Password is required'
                }
              ]}
            >
              {({ value }) => {
                return (
                  <form className="flex flex-col gap-6">
                    <FieldInput
                      label="Email"
                      type="email"
                      name="username"
                      placeholder="Enter your email address"
                    />
                    <FieldInput
                      label="Password"
                      type="password"
                      name="password"
                      placeholder="Enter Password"
                    />

                    <Button
                      variant="primary"
                      className="w-100 text-center"
                      preventDefault
                      formuxSubmit
                      isBusy={authSignIn.status.isBusy}
                      label="Login"
                      onClick={() => {
                        const { password, username } = value;

                        authSignIn.fetch(
                          { password, username },
                          {
                            onAfterSuccess: ({ message }) => {
                              showSuccessToast(message);
                              pushRoute('/');
                            }
                          }
                        );
                      }}
                    />

                    <p style={{ textAlign: 'right', padding: '5px' }}>
                      <span
                        style={{ color: '#02528A', cursor: 'pointer' }}
                        onClick={() => pushRoute('/forgetpassword')}
                      >
                        Forget Password{' '}
                      </span>
                    </p>
                  </form>
                );
              }}
            </Formux>
          </OutlinedBox>
          <div className="w-full flex justify-center mt-4">
            <p>
              Don’t have an account?{' '}
              <span onClick={() => pushRoute('/signup')} className="cursor-pointer text-sky-700">
                Create Account
              </span>
            </p>
          </div>
        </>
      }
    />
  );
};
