import { ModalContainer } from '@features/modal';
import { PersistentProvider } from '@features/persistent';
import { ReduxProvider } from '@features/redux/ReduxProvider';
import { RouterProvider } from '@features/router/RouterProvider';
import { ToastContainer } from '@features/toast';

export const Providers = ({ children }: { children: React.ReactNode }) => {
  return (
    <PersistentProvider>
      <ReduxProvider>
        <RouterProvider>
          <ToastContainer />
          <ModalContainer />
          {children}
        </RouterProvider>
      </ReduxProvider>
    </PersistentProvider>
  );
};
