import { Query, UrlParams } from '@app-types/api';
import { injectUrlParams } from '@utils/api';
import qs from 'query-string';

export const DEFAULT_PAGINATION = { page: 1 };

export const queryToSearch = (query: Query): string =>
  qs.stringify(query, {
    arrayFormat: 'bracket',
    skipEmptyString: true
  });

export const searchToQuery = (search: string): Query =>
  qs.parse(search, {
    decode: false, //https://github.com/ljharb/qs/issues/277
    parseNumbers: true,
    arrayFormat: 'bracket',
    parseBooleans: true
  });

export const fillBrowserRoute = ({
  path,
  query,
  urlParams
}: {
  path: string;
  query?: Query;
  urlParams?: UrlParams;
}) => {
  const flattenPath = injectUrlParams(path, urlParams);

  return `${flattenPath}${query ? `?${queryToSearch(query)}` : ''}`;
};
