import { HtmlTextContainer } from '@components/html-text-container';

import { OutlinedBox } from '@pages/@common/outlined-box';

import { TwoSideContainer } from './TwoSideContainer';

export const Section4 = () => {
  return (
    <section className="flex flex-col items-center gap-4 text-black bg-gray-200">
      <h2 className="text-5xl text-sky-600 font-bold mt-6 lg:mt-8 text-center">
        How is this possible?
      </h2>

      <TwoSideContainer
        leftContent={
          <div className="flex flex-col gap-4 text-2xl font-bold">
            <h4>Savings come from a variety of sources:</h4>
            <h4>We ask you to do some of the work:</h4>

            <OutlinedBox>
              <HtmlTextContainer className="text-lg text-lime-700 marker:text-sm font-normal">
                <ul>
                  <li>Take photos of the main service panel and of the exterior of the home. </li>

                  <li>Take photos of the roof rafters fhat arevisible in the attic.</li>

                  <li>
                    If you think you're up to installing the panels yourself, we will provide an
                    expertfor on-the-job training.
                  </li>
                </ul>
              </HtmlTextContainer>
            </OutlinedBox>
          </div>
        }
        rightContent={
          <div className="flex flex-col gap-4 text-2xl font-bold">
            <h4>* We have no salespeople, one of the large contributors to cost</h4>
            <h4>
              * We buy the equipment from top-tier suppliers at wholesale price and pass on the
              savings to you
            </h4>
            <h4>* We are a flat organization with little corporate overhead. </h4>
            <h4>
              * We have automated the design to avoid the need for on-the-premise solar designer{' '}
            </h4>
          </div>
        }
      />

      <h3 className="text-3xl font-bold mb-10 text-center">
        Come on... This is too good to be true...
      </h3>
    </section>
  );
};
