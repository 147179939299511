import { useFetch } from '@hooks/useFetch';

import { FetchResource } from '@app-types/api';
import { AnyRecord } from '@app-types/general';
import { ReportState } from '@app-types/reports';
import { getEndpoint } from '@utils/api';

type UseSaveReportArgs = ReportState & {
  timeZone?: string;
  consumption: Array<{
    month: string;
    avgkW: number;
  }>;
};

export const useAddReport = (): {
  addReport: FetchResource<UseSaveReportArgs, AnyRecord | boolean>;
} => {
  const fetch = useFetch<AnyRecord | boolean>();

  return {
    addReport: {
      data: fetch[0],
      status: fetch[1],
      fetch: (
        {
          systemTypeId,
          address,
          city,
          state,
          zip,
          buildingTypeId,
          coolingTypeId,
          heatingTypeId,
          roofAge,
          suiteNum,
          consumption,
          utilityCharge,
          estimatedkWPerHour,
          yearlyElectricityCost,
          percentOfEnergyToGenerate,
          energySupplierId,
          roles,
          timeZone
        },
        options = {}
      ) => {
        fetch[2](
          {
            method: 'post',
            url: getEndpoint({ path: '/report/save-report' }),
            data: {
              //@ts-expect-error TODO
              systemTypeId: parseInt(systemTypeId), //TODO
              roles,
              address,
              city,
              state,
              zip,
              //@ts-expect-error TODO
              buildingTypeId: parseInt(buildingTypeId), //TODO
              //@ts-expect-error TODO
              coolingTypeId: parseInt(coolingTypeId), //TODO
              //@ts-expect-error TODO
              heatingTypeId: parseInt(heatingTypeId), //TODO
              roofAge,
              suiteNum,
              consumption,
              utilityCharge,
              estimatedkWPerHour,
              yearlyElectricityCost,
              percentOfEnergyToGenerate,
              //@ts-expect-error TODO
              energySupplierId: parseInt(energySupplierId), //TODO
              timeZone
            }
          },
          options
        );
      },
      reset: fetch[3]
    }
  };
};
