import { useEffect } from 'react';

import { Map } from '@components/map';

import { FormContext } from './FormContext';
import { IrradianceMap } from './IrradianceMap';
import { ToolBox } from './tool-box';
import { MapSliderMonths } from './tool-box/MapSliderMonths';
import { useMapState } from './useMapState';
import {
  renderPanels,
  renderRidgeLine,
  renderSelectedArea,
  renderSelectingAreaMarkers
} from './utils';

import { AnyRecord } from '@app-types/general';

const MapPageComponent = () => {
  const { hasValidIrradianceMapData, value, setValue } = useMapState();

  const {
    selectingAreaMarkers,
    selectingArea,
    //
    selectedArea,
    ridgeLinePoints,
    //
    selectingRidgeLine,
    //
    searchedLocation,
    panels,
    mapOptions,
    irradianceMapData
  } = value;

  useEffect(() => {
    setValue((state) => ({ ...state, selectedArea: [], ridgeLinePoints: [], panels: [] }));
  }, [JSON.stringify(searchedLocation)]);

  return (
    <div className="relative w-screen h-screen">
      <Map
        className="w-screen h-screen"
        center={searchedLocation}
        onLoaded={() => {
          setValue((state) => ({ ...state, isLoaded: true }));
        }}
        mapOptions={mapOptions}
        onClick={(e: AnyRecord) => {
          if (selectingRidgeLine) {
            const newRidgeLinePoints = [
              ...ridgeLinePoints,
              { lat: e.latLng.lat(), lng: e.latLng.lng() }
            ];

            setValue((state) => ({ ...state, ridgeLinePoints: newRidgeLinePoints }));

            if (newRidgeLinePoints.length === 2) {
              setValue((state) => ({ ...state, selectingRidgeLine: false }));
            }
            return;
          }

          if (selectingArea) {
            return setValue((state) => ({
              ...state,
              selectingAreaMarkers: [
                ...state.selectingAreaMarkers,
                { lat: e.latLng.lat(), lng: e.latLng.lng() }
              ]
            }));
          }
        }}
      >
        {renderPanels(panels)}
        {renderRidgeLine(ridgeLinePoints)}

        {renderSelectedArea(selectedArea)}
        {renderSelectingAreaMarkers(selectingAreaMarkers)}

        <IrradianceMap url={irradianceMapData.url} bounds={irradianceMapData.bounds} />
      </Map>

      <ToolBox />

      {hasValidIrradianceMapData && <MapSliderMonths />}
    </div>
  );
};

export const MapPage = () => (
  <FormContext>
    <MapPageComponent />
  </FormContext>
);
