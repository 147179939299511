import { StyleProps } from '@app-types/general';

interface TwoSideContainerProps extends StyleProps {
  leftContent: React.ReactNode;
  rightContent: React.ReactNode;
}

export const TwoSideContainer = ({ leftContent, rightContent }: TwoSideContainerProps) => {
  return (
    <div className="mx-5 lg:mx-20 my-5 lg:my-10 flex flex-col lg:flex-row gap-6">
      <div className="w-full flex items-center justify-center">{leftContent}</div>
      <div className="w-full flex items-center justify-center">{rightContent}</div>
    </div>
  );
};
