import { IconButtonDetails } from '@components/icon-button-details';
import { IconButtonUpdate } from '@components/icon-button-update';

import { RowActionsContainer } from '@pages/@common/row-actions-container';
import { useAddUpdateComponentModal } from '@pages/@modals/useAddUpdateComponentModal';
import { useComponentDetailsModal } from '@pages/@modals/useComponentDetailsModal';

import { useGetComponentDetails } from '@api/component/useGetComponentDetails';
import { AppComponent } from '@app-types/general';

export interface RowActionsProps {
  rowData: AppComponent;
  onRefresh?: () => void;
}
export const RowActions = ({ rowData, onRefresh }: RowActionsProps) => {
  const { componentId } = rowData;

  const { getComponentDetails } = useGetComponentDetails();
  const { addUpdateComponentModal } = useAddUpdateComponentModal();

  const { componentDetailsModal } = useComponentDetailsModal();

  return (
    <RowActionsContainer>
      <IconButtonDetails
        onClick={() => {
          getComponentDetails.fetch(
            {
              componentId
            },
            {
              onAfterSuccess: ({ result }) => {
                componentDetailsModal.open({ component: result });
              }
            }
          );
        }}
      />
      <IconButtonUpdate
        onClick={() => {
          addUpdateComponentModal.open({ componentId, onAfterSuccess: onRefresh });
        }}
      />
    </RowActionsContainer>
  );
};
