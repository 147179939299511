import { useFetch } from '@hooks/useFetch';

import { FetchResource } from '@app-types/api';
import { AppComponent } from '@app-types/general';
import { getEndpoint } from '@utils/api';

export const useGetComponentDetails = (): {
  getComponentDetails: FetchResource<{ componentId: number }, AppComponent>;
} => {
  const fetch = useFetch<AppComponent>();

  return {
    getComponentDetails: {
      data: fetch[0],
      status: fetch[1],
      fetch: ({ componentId }, options = {}) => {
        fetch[2](
          {
            method: 'get',
            url: getEndpoint({
              path: '/component/get-component-details',
              query: {
                componentId
              }
            })
          },
          {
            ...options,
            onResponseProcessing: ({ message, result }) => ({
              message,
              result: {
                ...result,
                vendors: result.vendors?.map((vendor) => ({
                  ...vendor,
                  //@ts-expect-error ignore error from back
                  name: vendor.vendorName
                }))
              }
            })
          }
        );
      },
      reset: fetch[3]
    }
  };
};
