import { Slider, SliderProps } from '@components/slider';

export interface SliderMonths extends Pick<SliderProps, 'value' | 'onChange' | 'className'> {}

const months = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sept',
  'Oct',
  'Nov',
  'Dec'
];

export const SliderMonths = (props: SliderProps) => {
  return (
    <Slider
      markClassName="rounded-full size-2 bg-sky-500"
      thumbClassName="!w-10"
      marks
      min={0}
      max={11}
      renderThumb={(props, { valueNow }) => <div {...props}>{months[valueNow]}</div>}
      {...props}
    />
  );
};
