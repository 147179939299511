import { useFetch } from '@hooks/useFetch';

import { FetchResource } from '@app-types/api';
import { getEndpoint } from '@utils/api';

export const useAuthForgotPassword = (): {
  authForgotPassword: FetchResource<{ email: string }, void>;
} => {
  const fetch = useFetch();

  return {
    authForgotPassword: {
      data: fetch[0],
      status: fetch[1],
      fetch: ({ email }, options = {}) => {
        fetch[2](
          {
            method: 'get',
            url: getEndpoint({ path: '/account/forgot-password', query: { email } })
          },
          options
        );
      },
      reset: fetch[3]
    }
  };
};
