import ReactSlider, { ReactSliderProps } from 'react-slider';

import { StyleProps } from '@app-types/general';
import { cn } from '@utils/general';

/**
 * https://zillow.github.io/react-slider/
 */

export interface SliderProps extends ReactSliderProps, StyleProps {}

export const Slider = ({ className, thumbClassName, trackClassName, ...props }: SliderProps) => {
  return (
    <ReactSlider
      className={cn('h-6 w-full flex items-center', className)}
      thumbClassName={cn(
        'size-7 bg-lime-500 rounded-full flex items-center justify-center text-white font-bold cursor-pointer',
        thumbClassName
      )}
      trackClassName={cn('bg-gray-300 h-3', trackClassName)}
      {...props}
    />
  );
};
