import { PersistentProviderWeb } from './PersistentProviderWeb';
import { usePersistentContext } from './usePersistentContext';

import { ChildrenProp } from '@app-types/general';
import { backdoor } from '@utils/backdoor';

const BackdoorFactory = () => {
  backdoor.persistent = usePersistentContext();
  return null;
};

export const PersistentProvider = ({ children }: ChildrenProp) => {
  return (
    <PersistentProviderWeb>
      <BackdoorFactory />
      {children}
    </PersistentProviderWeb>
  );
};
