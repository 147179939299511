import { useMapState } from './useMapState';
import { getBoundingBox } from './utils';

import { useDownloadGeoTiff } from '@api/map/useDownloadGeoTiff';
import { useGetDataLayer } from '@api/map/useGetDataLayer';
import { MapStateIrradianceMapData } from '@app-types/map';
import { isNullOrUndefined } from '@utils/general';
import { getBoundingBoxCorners, getCenterPoint, renderPalette } from '@utils/map';

export const useIrradianceMap = () => {
  const { setValue, value } = useMapState();
  const { selectedArea, irradianceMapData } = value;

  const { getDataLayer } = useGetDataLayer();
  const { downloadGeoTiff } = useDownloadGeoTiff();

  const onDownloadGeoTiff = (data: Pick<MapStateIrradianceMapData, 'dataLayer' | 'month'>) => {
    const { month, dataLayer } = data;

    if (isNullOrUndefined(dataLayer)) return;

    const { monthlyFluxUrl } = dataLayer;

    downloadGeoTiff.fetch(
      { url: monthlyFluxUrl },
      {
        onAfterSuccess: async ({ result }) => {
          const renderPaletteData = await renderPalette({
            data: result,
            mask: undefined,
            colors: ['00000A', '91009C', 'E64616', 'FEB400', 'FFFFF6'],
            min: 0,
            max: 200,
            month
          });

          const getIrradianceMapBounds = () => {
            const boundingBox = getBoundingBox(selectedArea);

            let { minLat, maxLat, minLng, maxLng } = boundingBox;
            // Adjust the bounding box to ensure it covers the whole house
            const adjustmentFactor = 0.0001; // Adjust this factor to fine-tune the area covered

            minLat -= adjustmentFactor;
            maxLat += adjustmentFactor;
            minLng -= adjustmentFactor;
            maxLng += adjustmentFactor;

            return { north: maxLat, south: minLat, west: minLng, east: maxLng };
          };

          setValue((state) => ({
            ...state,
            irradianceMapData: {
              ...state.irradianceMapData,
              url: renderPaletteData.toDataURL(),
              bounds: getIrradianceMapBounds()
            }
          }));
        }
      }
    );
  };

  const onComputeIrradianceMap = () => {
    const { ne, sw } = getBoundingBoxCorners(selectedArea);

    const neLatLng = new google.maps.LatLng(ne.lat, ne.lng);
    const swLatLng = new google.maps.LatLng(sw.lat, sw.lng);
    const diameter = google.maps.geometry.spherical.computeDistanceBetween(neLatLng, swLatLng);
    const radius = Math.ceil(diameter / 2);

    const { lat, lng } = getCenterPoint(selectedArea);
    return getDataLayer.fetch(
      {
        lat,
        lng,
        radius
      },
      {
        onAfterSuccess: ({ result }) => {
          setValue((state) => ({
            ...state,
            irradianceMapData: {
              ...state.irradianceMapData,
              dataLayer: result
            }
          }));

          onDownloadGeoTiff({
            dataLayer: result,
            month: irradianceMapData.month
          });
        }
      }
    );
  };

  return {
    onComputeIrradianceMap,
    onDownloadGeoTiff
  };
};
