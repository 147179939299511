import { EmergentProps } from '@features/modal/components/emergent';
import { useModal } from '@features/modal/useModal';

import { AnyRecord } from '@app-types/general';

export const useModalPage = <T extends AnyRecord | void = void>(
  propsFactory: (args: T) => EmergentProps
) => {
  const { pushModal } = useModal();

  return {
    open: (args: T) => {
      pushModal('Emergent', propsFactory(args));
    }
  };
};
