import { Link } from 'react-router-dom';

import { Button } from '@components/button';
import { FieldCheckbox } from '@components/field-checkbox';
import { FieldInput } from '@components/field-input';
import { Formux } from '@components/formux';

import { useRouter } from '@features/router/useRouter';
import { showSuccessToast } from '@features/toast';

import { AuthContainer } from '@pages/@common/auth-container';
import { AuthIconUser } from '@pages/@common/auth-icon-user';
import { OutlinedBox } from '@pages/@common/outlined-box';

import { useAuthSignUp } from '@api/auth/useAuthSignUp';

export const SignUp = () => {
  const { pushRoute } = useRouter();

  const { authSignUp } = useAuthSignUp();

  return (
    <AuthContainer
      formContent={
        <>
          <OutlinedBox>
            <div className="flex flex-col items-center gap-2">
              <AuthIconUser />
              <p className="text-2xl text-black font-normal">Welcome to USRoofs</p>
              <p className="text-sm">Create your free account now</p>
            </div>

            <Formux
              value={{ firstName: '', lastName: '', email: '', password: '', PhoneNumber: '' }}
              validate={[
                {
                  field: 'firstName',
                  type: 'required',
                  message: 'First name is required'
                },
                {
                  field: 'lastName',
                  type: 'required',
                  message: 'Last name is required'
                },
                {
                  field: 'email',
                  type: 'email'
                },
                {
                  field: 'email',
                  type: 'required',
                  message: 'Email is required'
                },
                {
                  field: 'PhoneNumber',
                  type: 'required',
                  message: 'Phone is required'
                },
                {
                  field: 'password',
                  type: 'required',
                  message: 'Password is required'
                }
              ]}
            >
              {({ value }) => {
                return (
                  <form className="flex flex-col gap-4 mt-4">
                    <div className="flex flex-col lg:flex-row gap-6">
                      <FieldInput
                        label="First Name"
                        name="firstName"
                        placeholder="Enter first name"
                        className="w-full"
                      />

                      <FieldInput
                        label="Last Name"
                        name="lastName"
                        placeholder="Enter last name"
                        className="w-full"
                      />
                    </div>

                    <FieldInput
                      label="Email"
                      type="email"
                      name="email"
                      placeholder="Enter your email address"
                    />

                    <FieldInput
                      label="Phone Number"
                      type="number"
                      name="PhoneNumber"
                      placeholder="Enter phone number"
                    />

                    <FieldInput
                      label="Password"
                      type="password"
                      name="password"
                      placeholder="Enter Password"
                    />

                    <FieldCheckbox
                      label={
                        <>
                          I have read and agree to the{' '}
                          <Link className="text-sky-800" to="/terms_condition">
                            Terms & Conditions
                          </Link>{' '}
                          and{' '}
                          <Link className="text-sky-800" to="/privacy_policy">
                            Privacy Policy
                          </Link>
                          .
                        </>
                      }
                      name="check"
                    />

                    <Button
                      variant="primary"
                      className="w-100 text-center"
                      preventDefault
                      formuxSubmit
                      isBusy={authSignUp.status.isBusy}
                      label="Create Account"
                      onClick={() => {
                        const { password, email, firstName, lastName, PhoneNumber } = value;

                        authSignUp.fetch(
                          {
                            email,
                            firstName,
                            lastName,
                            PhoneNumber,
                            password
                          },
                          {
                            onAfterSuccess: ({ message }) => {
                              showSuccessToast(message);
                              pushRoute('/login');
                            }
                          }
                        );
                      }}
                    />
                  </form>
                );
              }}
            </Formux>
          </OutlinedBox>
          <div className="w-full flex justify-center mt-4">
            <p>
              Already have an account?{' '}
              <span
                onClick={() => pushRoute('/login')}
                style={{ cursor: 'pointer', color: '#02528A' }}
              >
                Login
              </span>
            </p>
          </div>
        </>
      }
    />
  );
};
