import { isEmpty, isNullOrUndefined } from './general';

import { FetchStatus, GetEndpoint, Query, UrlParams } from '@app-types/api';
import qs from 'query-string';

export const dummyStatus: FetchStatus = {
  error: null,
  isBusy: false,
  isFailed: false,
  isNotStarted: true,
  isSuccess: false,
  wasCalled: false
};

export const paramsSerializer = (query: Query): string =>
  qs.stringify(query, { arrayFormat: 'comma' });

export const getEndpointUrl = () => {
  return 'https://api-dev.bessandsolar.com/api';
};

export const getEndpointSolarUrl = () => {
  return 'https://solar.googleapis.com/v1';
};

export const injectUrlParams = (url: string, urlParams: UrlParams = {}): string => {
  let filledUrl = url;

  Object.entries(urlParams).forEach(([key, value]) => {
    const pattern = `:${key}`;
    if (filledUrl.includes(pattern) && value !== undefined) {
      filledUrl = filledUrl.replace(pattern, value.toString());
    }
  });

  return filledUrl;
};

export const fillPath = ({
  path,
  query,
  urlParams
}: {
  path: string;
  query?: Query;
  urlParams?: UrlParams;
}): string => {
  const flattenPath = injectUrlParams(path, urlParams);

  const getFlattenParams = (value: Query): Query =>
    Object.entries(value).reduce((acc, [k, v]) => {
      if (isNullOrUndefined(v)) return acc;
      return { ...acc, [k]: v };
    }, {});

  const flattenParams = query && getFlattenParams(query);

  if (isEmpty(flattenParams)) {
    return flattenPath;
  }

  return `${flattenPath}?${paramsSerializer(flattenParams)}`;
};

export const getEndpointSolar: GetEndpoint = ({ path, query, urlParams }) => {
  const flattenPath = fillPath({
    path,
    query,
    urlParams
  });

  return `${getEndpointSolarUrl()}${flattenPath}`;
};

export const getEndpoint: GetEndpoint = ({ path, query, urlParams }) => {
  const flattenPath = fillPath({
    path,
    query,
    urlParams
  });

  return `${getEndpointUrl()}${flattenPath}`;
};
