import { StyleProps } from '@app-types/general';
import { cn } from '@utils/general';

export interface StepHeader extends StyleProps {
  title: string;
  subtTitle: string;
}

export const StepHeader = ({ subtTitle, title, className }: StepHeader) => {
  return (
    <div className={cn('flex flex-col items-center text-black gap-4', className)}>
      <h1 className="text-4xl font-bold">{title}</h1>
      <p className="font-semibold text-lg">{subtTitle}</p>
    </div>
  );
};
