import { Button } from '@components/button';
import { IconButtonUpdate } from '@components/icon-button-update';

import { RowActionsContainer } from '@pages/@common/row-actions-container';
import { useAddUpdateDesingModal } from '@pages/@modals/useAddUpdateDesingModal';
import { useDesingVersionsModal } from '@pages/@modals/useDesingVersionsModal';

import { SolarDesign } from '@app-types/general';

export interface RowActionsProps {
  rowData: SolarDesign;
  onAfterSuccess?: () => void;
}
export const RowActions = ({ rowData, onAfterSuccess }: RowActionsProps) => {
  const { addUpdateDesingModal } = useAddUpdateDesingModal();
  const { desingVersionsModal } = useDesingVersionsModal();

  return (
    <RowActionsContainer>
      <IconButtonUpdate
        onClick={() => {
          addUpdateDesingModal.open({ design: rowData, onAfterSuccess });
        }}
      />
      <Button
        label="Versions"
        variant="link"
        narrow
        onClick={() => {
          desingVersionsModal.open({ design: rowData, onAfterSuccess });
        }}
      />
    </RowActionsContainer>
  );
};
