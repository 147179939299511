import { IconButton, IconButtonProps } from '@components/icon-button';

import SvgEditSolid from '@icons/EditSolid';
import { cn } from '@utils/general';

export interface IconButtonUpdateProps extends IconButtonProps {}

export const IconButtonUpdate = ({ className, ...props }: IconButtonUpdateProps) => (
  <IconButton
    svg={SvgEditSolid}
    title="Edit"
    {...props}
    className={cn('fill-green-700', className)}
  />
);
