import { useEffect } from 'react';

import { ButtonNew } from '@components/button-new';
import { ButtonRefresh } from '@components/button-refresh';
import { Pagination } from '@components/pagination';
import { Table } from '@components/table';

import { OutlinedBox } from '@pages/@common/outlined-box';
import { TopActions } from '@pages/@common/top-actions';
import { useAddUpdateComponentModal } from '@pages/@modals/useAddUpdateComponentModal';
import { StepHeader } from '@pages/admin-page/components/StepHeader';

import { RowActions } from './RowActions';

import { useGetComponents } from '@api/component/useGetComponents';

export const StepComponents = () => {
  const { getComponents } = useGetComponents();

  const { addUpdateComponentModal } = useAddUpdateComponentModal();

  const handleRefresh = () => getComponents.fetch();

  useEffect(() => {
    handleRefresh();
  }, []);

  return (
    <OutlinedBox>
      <StepHeader title="Components" subtTitle="Available components" />

      <TopActions>
        <ButtonNew
          label="Add Component"
          className="ml-auto"
          onClick={() => {
            addUpdateComponentModal.open({ onAfterSuccess: handleRefresh });
          }}
        />
        <ButtonRefresh onClick={handleRefresh} />
      </TopActions>

      <Table
        data={getComponents.data?.result.data}
        isBusy={getComponents.status.isBusy}
        heads={['Type', 'Brand', 'Model', 'Power', 'Image URL', 'Datasheet', 'Actions']}
        emptyMessage="No designs available"
        getRowProps={(rowData, index) => {
          const { brand, type, model, datasheet, imageUrl, power } = rowData;

          return {
            nodes: [
              type,
              brand,
              model,
              power,
              imageUrl || 'N/A',
              datasheet || 'N/A',
              <RowActions key={index} rowData={rowData} onRefresh={handleRefresh} />
            ]
          };
        }}
        className="h-96"
      />

      <Pagination
        paginatedData={getComponents.data?.result}
        onChangePage={(page) => getComponents.fetch({ page })}
        className="mt-4 ml-auto"
      />
    </OutlinedBox>
  );
};
