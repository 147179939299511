import { FormContextState } from '@components/formux/types';
import { useForm } from '@components/formux/useForm';

import { MapState } from '@app-types/map';

interface UseMapStateReturn extends FormContextState<MapState> {
  hasValidSelectedArea: boolean;
  hasValidRidgeLine: boolean;
  hasValidIrradianceMapData: boolean;
}

export const useMapState = (): UseMapStateReturn => {
  const formState = useForm();

  const { selectedArea, ridgeLinePoints, selectingArea, selectingRidgeLine, irradianceMapData } =
    formState.value;

  return {
    ...(formState as unknown as FormContextState<MapState>),
    hasValidSelectedArea: selectedArea.length >= 3 && !selectingArea,
    hasValidRidgeLine: ridgeLinePoints.length == 2 && !selectingRidgeLine,
    hasValidIrradianceMapData: !!irradianceMapData.url && !!irradianceMapData.bounds
  };
};
