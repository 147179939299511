import { SkeletonBody } from './SkeletonBody';
import { TableRow } from './table-row';
import { TableProps } from './types';

import { AnyRecord } from '@app-types/general';
import { cn } from '@utils/general';

export const Table = <RowData extends AnyRecord = AnyRecord>(props: TableProps<RowData>) => {
  const { propsPreprocessors, ...initialProps } = props;

  const {
    heads,
    getRowProps,
    data,
    className,
    //
    isBusy,
    emptyMessage = 'There is nothing to show',
    getHeadStyles
    //
  } = (propsPreprocessors || []).reduce(
    (acc, propsPreprocessor) => ({ ...acc, ...propsPreprocessor(acc) }),
    initialProps
  );

  const renderUnderTable = () => {
    if (isBusy) return;

    if (!data?.length) {
      return <div className="flex items-center justify-center text-3xl h-96">{emptyMessage}</div>;
    }

    return null;
  };

  const renderBody = () => {
    if (isBusy) {
      return <SkeletonBody headsAmount={heads.length} />;
    }

    return (
      <tbody className="bg-white">
        {data?.map((d, index) => {
          const rowProps = getRowProps(d, index);
          return <TableRow key={index} {...rowProps} index={index} />;
        })}
      </tbody>
    );
  };
  return (
    <div className={cn('relative max-h-screen overflow-auto', className)}>
      <table className="relative min-w-full max-h-full table-auto text-black">
        <thead className="sticky top-0 z-10">
          <tr>
            {heads.map((head, index) => {
              return (
                <th
                  key={index}
                  className={cn(
                    'px-6 py-3 text-xs font-bold leading-4 tracking-wider text-left  uppercase border',
                    'text-white border-gray-200 bg-blue-600',
                    getHeadStyles?.({ index })
                  )}
                >
                  {head}
                </th>
              );
            })}
          </tr>
        </thead>

        {renderBody()}
      </table>

      {renderUnderTable()}
    </div>
  );
};
