import { useFetch } from '@hooks/useFetch';

import { FetchResource } from '@app-types/api';
import { AuthData } from '@app-types/auth';
import { getEndpoint } from '@utils/api';

export const useAuthSignIn = (): {
  authSignIn: FetchResource<{ username: string; password: string }, AuthData>;
} => {
  const fetch = useFetch<AuthData>();

  return {
    authSignIn: {
      data: fetch[0],
      status: fetch[1],
      fetch: ({ username, password }, options = {}) => {
        fetch[2](
          {
            method: 'post',
            url: getEndpoint({ path: '/account/signin' }),
            data: { username, password }
          },
          options
        );
      },
      reset: fetch[3]
    }
  };
};
