import { Divider } from '@components/divider';

import { Actions } from './Actions';
import { BuildingAddress } from './BuildingAddress';
import { PanelsFeatures } from './PanelsFeatures';

import { StyleProps } from '@app-types/general';
import { cn } from '@utils/general';

export interface ToolBoxProps extends StyleProps {}

export const ToolBox = ({ className }: ToolBoxProps) => {
  return (
    <div
      className={cn(
        'absolute top-20 left-10 z-10 w-80 h-[50rem] bg-white/90 rounded-lg px-6 py-4 max-h-[80vh] overflow-auto',
        className
      )}
    >
      <BuildingAddress />

      <Divider className="border-sky-600" />

      <PanelsFeatures />

      <Divider className="border-sky-600" />

      <Actions />
    </div>
  );
};
