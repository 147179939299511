import { slices } from '@features/slices';

import { FetchResponse } from '@app-types/api';
import { AuthData } from '@app-types/auth';
import { AnyRecord } from '@app-types/general';
import { configureStore } from '@reduxjs/toolkit';
import { dummyStatus } from '@utils/api';
import { backdoor } from '@utils/backdoor';
import { combineReducers } from 'redux';

export const makerStore = async (preloadedState: Partial<AnyRecord> = {}) => {
  const enhancedReducers = combineReducers({
    ...Object.values(slices).reduce((r, { name, reducer }) => ({ ...r, [name]: reducer }), {})
  });

  const store = configureStore({
    reducer: enhancedReducers,
    preloadedState,
    devTools: true,
    middleware: (getDefaultMiddleware) => {
      //https://stackoverflow.com/questions/61704805/getting-an-error-a-non-serializable-value-was-detected-in-the-state-when-using
      return getDefaultMiddleware({
        serializableCheck: false
      });
    }
  });

  // setting authentication data
  const authData = await backdoor.persistent.getPersistent('authData');

  if (authData) {
    const data: FetchResponse<AuthData> = {
      result: authData,
      message: ''
    };

    store.dispatch(
      slices.useAuth.actions.setState({
        data,
        status: dummyStatus
      })
    );
  }

  backdoor.store = {
    dispatch: (...args) => store.dispatch(...args),
    getState: () => store.getState(),
    subscribe: (...args) => store.subscribe(...args)
  };

  return { store };
};
