import { useFetch } from '@hooks/useFetch';

import { FetchResourceWithPagination, PaginatedData, PaginationQuery } from '@app-types/api';
import { Vendor } from '@app-types/general';
import { getEndpoint } from '@utils/api';
import { defaultPaginationQuery } from '@utils/pagination';

export const useGetVendors = (): {
  getVendors: FetchResourceWithPagination<void | PaginationQuery, Vendor>;
} => {
  const fetch = useFetch<PaginatedData<Vendor>>();

  return {
    getVendors: {
      data: fetch[0],
      status: fetch[1],
      fetch: (query = {}, options = {}) => {
        fetch[2](
          {
            method: 'get',
            url: getEndpoint({
              path: '/vendor/get-vendors',
              query: {
                ...defaultPaginationQuery,
                ...query
              }
            })
          },
          options
        );
      },
      reset: fetch[3]
    }
  };
};
