import { Button } from '@components/button';
import { ButtonClose } from '@components/button-close';

import { useModal } from '@features/modal/useModal';

import SolarModelImage from '@assets/solar-model-2.jpg';
import bannerImage from '@assets/Solar-Panel-1.jpg';

import { cn } from '@utils/general';

export const Section0 = () => {
  const { pushModal } = useModal();

  return (
    <>
      <section>
        <div
          className={cn(
            'relative h-[calc(100vh-160px)] bg-no-repeat bg-cover bg-center flex items-center'
          )}
          style={{ backgroundImage: `url(${bannerImage})` }}
        >
          <div className={cn('bg-gradient-to-r from-sky-900 to-transparent absolute inset-0')} />

          <div className="text-white z-10 mx-5 lg:mx-20 w-11/12 lg:w-1/2 flex flex-col gap-4">
            <h1 className="text-5xl font-bold">
              Design your own Solar System and save 30 to 40 percent
            </h1>
            <p className="text-2xl">A buck ten per wattt</p>

            <Button
              label="Find Out How"
              onClick={() => {
                pushModal('Emergent', {
                  useProps: () => {
                    return {
                      content: (
                        <div className="flex gap-8">
                          <img
                            src={SolarModelImage}
                            className="hidden lg:block w-1/2 object-cover"
                          />
                          <div className="w-full flex flex-col gap-4 text-gray-800">
                            <p>
                              Did you know that 60 percent of the cost solar installation is for
                              sales and overhead?
                            </p>
                            <p>Design your own system and eliminate this overhead.</p>
                            <p>
                              Using Our AI-assisted SolarDesigner you do not have to be an engineer
                              to design a solar system.
                            </p>
                            <p>
                              Our easy-to-follow User Interface will step you through the process.
                              If you are comfortable with the Google Earth user interface you are
                              ready to take control of your solar project.
                            </p>
                            <p>
                              The preliminary design is free. We provide you with all you need, from
                              a complete list of the number of panels, wiring, inverter, junction
                              boxes, breakers, prices for each component, instructions, and the
                              permit application to submit to your City Building Department.
                            </p>
                            <p>
                              And that is not all.... We will also finance your system and assign a
                              project manager to oversee the installation, if you prefer to have a
                              Certified Solar Installer do the work
                            </p>
                            <p>
                              We encourage you to shop around and compare our offering. You will not
                              find a better deal. The competition, after all, has all that overhead
                              to cover.
                            </p>
                            <p>
                              By designing your own system you cut the cost by 30 to 50 percent. At
                              no risk.
                            </p>
                          </div>
                        </div>
                      ),
                      closeButton: <ButtonClose />,
                      className: '!max-w-[60rem]'
                    };
                  }
                });
              }}
              rounded
              className="w-fit"
            />
          </div>
        </div>
      </section>
    </>
  );
};
