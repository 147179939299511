import { AuthData } from '@app-types/auth';
import { backdoor } from '@utils/backdoor';

export const resetPersistentAuthData = () => {
  const { removePersistent } = backdoor.persistent;

  removePersistent('authData');
};

export const setPersistentAuthData = ({ authData }: { authData?: AuthData }) => {
  const { setPersistent } = backdoor.persistent;

  if (authData) {
    setPersistent('authData', authData);
  }
};

export const getPersistentAuthData = async (): Promise<{
  authData: AuthData | null;
}> => {
  const { getPersistent } = backdoor.persistent;

  const [authData] = await Promise.all([getPersistent('authData')]);

  return {
    authData
  };
};
