import { ChildrenProp, StyleProps } from '@app-types/general';
import { cn } from '@utils/general';

export interface OutlinedBoxProps extends ChildrenProp, StyleProps {
  title?: string;
  titleBgVariant?: 'light' | 'dark';
}

export const OutlinedBox = ({
  children,
  className,
  title,
  titleBgVariant = 'dark'
}: OutlinedBoxProps) => (
  <div className={cn('bg-white ring-1 ring-gray-200 rounded-lg flex flex-col', className)}>
    {title && (
      <div
        className={cn('flex items-center justify-center text-xl font-bold rounded-t-lg py-3', {
          'bg-sky-800 text-white': titleBgVariant === 'dark'
        })}
      >
        {title}
      </div>
    )}

    <div className="p-5">{children}</div>
  </div>
);
