import homepanel from '@assets/homepanels.png';

import { TwoSideContainer } from './TwoSideContainer';

export const Section3 = () => {
  return (
    <section>
      <TwoSideContainer
        leftContent={
          <div className="text-2xl text-black font-bold flex flex-col gap-4 text-center lg:text-start">
            <h4> If you want the to ensure against grid outages lasting more than an hour.</h4>
            <h4>Opt for a battery backup that uses solar panels to charge the battery.</h4>
            <h4>No space indors? We will install a vertsion that works outdoors. </h4>
            <h4>
              You are the only one that can make the decsion of which, if any, of these options is
              best.
            </h4>
            <h4>
              The BessAndSolar platform allows you to explore and configure a system that fits your
              needs or goals.
            </h4>
            <h4>No high-pressure salesman will visit you and meke these decisions for you</h4>
          </div>
        }
        rightContent={
          <img src={homepanel} alt="all in one" className="rounded-full w-8/12 lg:w-full" />
        }
      />
    </section>
  );
};
