import allone from '@assets/allinone.png';

import { TwoSideContainer } from './TwoSideContainer';

export const Section2 = () => {
  return (
    <section>
      <TwoSideContainer
        leftContent={
          <div className="text-2xl text-black font-bold flex flex-col gap-4 lg:gap-8 text-center lg:text-left">
            <h4>
              This All-in-one Battery backup, having equivalent capacity to a Tesla PowerWall.
            </h4>
            <h4>The unit costs $6,500 installed and will power most homes for 24 hours.</h4>
            <h4 className="mt-3">Uses: Load shifting, Emergency power.</h4>
          </div>
        }
        rightContent={
          <div className="w-8/12 lg:w-8/12">
            <img src={allone} alt="all in one" className="w-full" />
            <div className="flex items-center justify-center text-center leading-6 bg-lime-400 text-black font-bold gap-2 p-2">
              Also available in a smaller 5 kWh size
              <br />
              Battery is charged by the grid
            </div>
          </div>
        }
      />
    </section>
  );
};
