import {
  NextButton,
  PageButton,
  Pagination as PaginationBase,
  PrevButton
} from 'react-headless-pagination';

import { IconButton } from '@components/icon-button';

import { PaginatedData } from '@app-types/api';
import { StyleProps } from '@app-types/general';
import SvgAngleLeftSolid from '@icons/AngleLeftSolid';
import SvgAngleRightSolid from '@icons/AngleRightSolid';
import { cn, isNullOrUndefined } from '@utils/general';

interface PaginationProps extends StyleProps {
  onChangePage?: (page: number) => void;
  paginatedData?: Pick<PaginatedData<any>, 'dataCount' | 'page' | 'pagesCount'>;
}

export const Pagination = ({ onChangePage, className, paginatedData }: PaginationProps) => {
  const { page, pagesCount } = paginatedData || {};

  if (isNullOrUndefined(page) || isNullOrUndefined(pagesCount)) return null;

  return (
    <PaginationBase
      className={cn(
        'flex items-center w-fit h-10 text-sm select-none text-black no-bullets',
        className
      )}
      currentPage={page - 1}
      edgePageCount={2}
      middlePagesSiblingCount={1}
      setCurrentPage={(p) => onChangePage?.(p + 1)}
      totalPages={pagesCount}
      truncableClassName="w-10 px-0.5 text-center"
      truncableText="..."
    >
      <PrevButton className="flex items-center mr-2 text-gray-500 hover:text-gray-600 dark:hover:text-gray-200 focus:outline-none opacity-50">
        <IconButton svg={SvgAngleLeftSolid} />
      </PrevButton>
      <div className="flex items-center justify-center flex-grow ">
        <PageButton
          activeClassName="bg-sky-100 text-sky-900"
          className="flex items-center justify-center h-10 w-10 rounded-full cursor-pointer"
          inactiveClassName="text-gray-500"
        />
      </div>
      <NextButton className="flex items-center mr-2 text-gray-500 hover:text-gray-600 dark:hover:text-gray-200 focus:outline-none cursor-pointer">
        <IconButton svg={SvgAngleRightSolid} />
      </NextButton>
    </PaginationBase>
  );
};
