import { Button } from '@components/button';
import { FieldInput } from '@components/field-input';
import { Formux } from '@components/formux';

import { useRouter } from '@features/router/useRouter';
import { showErrorToast, showSuccessToast } from '@features/toast';

import { AuthContainer } from '@pages/@common/auth-container';
import { AuthIconUser } from '@pages/@common/auth-icon-user';
import { OutlinedBox } from '@pages/@common/outlined-box';

import { useAuthResetPassword } from '@api/auth/useAuthResetPassword';

export const PasswordRecovery = () => {
  const { pushRoute, query } = useRouter();

  const { authResetPassword } = useAuthResetPassword();

  return (
    <AuthContainer
      formContent={
        <>
          <OutlinedBox>
            <div className="flex flex-col items-center gap-2">
              <AuthIconUser />
              <p className="text-2xl text-black font-normal">Reset Your Password</p>
            </div>

            <Formux
              value={{ password: '', confirm_password: '' }}
              validate={[
                {
                  field: 'password',
                  type: 'required',
                  message: 'Password is required'
                },
                {
                  field: 'confirm_password',
                  type: 'required',
                  message: 'Confirm Password is required'
                },
                {
                  field: 'confirm_password',
                  type: 'equal',
                  equalField: 'password',
                  message: 'The passwords must be the same'
                }
              ]}
            >
              {({ value }) => {
                return (
                  <form className="flex flex-col gap-4">
                    <FieldInput
                      label="Password"
                      type="password"
                      name="password"
                      placeholder="Enter your password"
                    />

                    <FieldInput
                      label="Confirm Password"
                      type="password"
                      name="confirm_password"
                      placeholder="Re-Enter Password"
                    />

                    <Button
                      variant="primary"
                      className="w-100 text-center"
                      formuxSubmit
                      preventDefault
                      isBusy={authResetPassword.status.isBusy}
                      label="Submit"
                      onClick={() => {
                        const { confirm_password, password } = value;
                        if (password !== confirm_password) {
                          return showErrorToast('Password and confirm-password does not match');
                        }

                        const token = query?.token as string | undefined;
                        const email = query?.email as string | undefined;

                        if (!token || !email) {
                          return showErrorToast('the token and email are required');
                        }

                        authResetPassword.fetch(
                          {
                            password,
                            token,
                            email
                          },
                          {
                            onAfterSuccess: ({ message }) => {
                              showSuccessToast(message);
                              pushRoute('/');
                            }
                          }
                        );
                      }}
                    />
                  </form>
                );
              }}
            </Formux>
          </OutlinedBox>
        </>
      }
    />
  );
};
