import { useEffect, useState } from 'react';

//getted from https://tailwindcss.com/docs/screens
const screens = {
  xs: '(max-width: 640px)',
  // => @media (max-width: 640px) { ... }

  sm: '(min-width: 640px)',
  // => @media (min-width: 640px) { ... }

  md: '(min-width: 768px)',
  // => @media (min-width: 768px) { ... }

  lg: '(min-width: 1024px)',
  // => @media (min-width: 1024px) { ... }

  xl: '(min-width: 1280px)',
  // => @media (min-width: 1280px) { ... }

  '2xl': '(min-width: 1536px)'
  // => @media (min-width: 1536px) { ... }
};

//getted from https://gist.github.com/SimeonGriggs/7071958b8a629faf9137734aec713a0c?permalink_comment_id=4980403#gistcomment-4980403
export const useBreakpoint = (query: keyof typeof screens): boolean => {
  const matchQueryList = window.matchMedia(screens[query]);

  const [match, setMatch] = useState<boolean>(matchQueryList.matches);

  const onChange = (e: MediaQueryListEvent) => setMatch(e.matches);

  useEffect(() => {
    matchQueryList.addEventListener('change', onChange);
    return () => matchQueryList.removeEventListener('change', onChange);
  }, [query]);

  return match;
};
