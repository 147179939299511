import { Navigate } from 'react-router-dom';

import { useAuth } from '@hooks/useAuth';

import { UserRoleName } from '@app-types/auth';
import { ChildrenProp } from '@app-types/general';

export const withAutorized = (rolesNames: Array<UserRoleName>) => {
  const AutorizedComponent = ({ children }: ChildrenProp) => {
    const { authData } = useAuth();

    const roleName = authData?.roleName;

    if (rolesNames.includes('Admin') && roleName === 'Admin') {
      return <>{children}</>;
    }

    if (rolesNames.includes('SuperAdmin') && roleName === 'SuperAdmin') {
      return <>{children}</>;
    }

    if (rolesNames.includes('User') && roleName === 'User') {
      return <>{children}</>;
    }

    return <Navigate to="/not-found" />;
  };

  const AutenticatedComponent = ({ children }: ChildrenProp) => {
    const { authData } = useAuth();

    if (!authData) {
      return <Navigate to="/login" />;
    }

    return <AutorizedComponent>{children}</AutorizedComponent>;
  };

  return AutenticatedComponent;
};
