import { useFetch } from '@hooks/useFetch';

import { FetchResource } from '@app-types/api';
import { getEndpoint } from '@utils/api';

export const useGetReports = (): {
  getReports: FetchResource<void, Array<any>>;
} => {
  const fetch = useFetch<Array<any>>();

  return {
    getReports: {
      data: fetch[0],
      status: fetch[1],
      fetch: (_, options = {}) => {
        fetch[2](
          {
            method: 'get',
            url: getEndpoint({ path: '/report/get-reports' })
          },
          options
        );
      },
      reset: fetch[3]
    }
  };
};
