import { Coordinates, GeoTiff, MapState, PanelOrientation } from '@app-types/map';

export const defaultPanelFeatureState: MapState['panelFeatureState'] = {
  panelsOrientation: PanelOrientation.PORTRAIT,
  panelsAmount: undefined,
  panelsModelId: undefined,
  panelsSeparation: undefined
};

export const getCenterPoint = (coordinates: Array<Coordinates>): Coordinates => {
  const lat = coordinates.reduce((acc, { lat }) => lat + acc, 0) / coordinates.length;
  const lng = coordinates.reduce((acc, { lng }) => lng + acc, 0) / coordinates.length;

  return { lat, lng };
};

export const getBoundingBoxCorners = (coordinates: Array<Coordinates>) => {
  const flatCoordinates = coordinates.flat();

  // Initialize min and max values using the first coordinate
  let minLat = flatCoordinates[0].lat;
  let maxLat = flatCoordinates[0].lat;
  let minLng = flatCoordinates[0].lng;
  let maxLng = flatCoordinates[0].lng;

  // Iterate over the coordinates to find the min/max latitude and longitude
  flatCoordinates.forEach((coord) => {
    if (coord.lat < minLat) minLat = coord.lat;
    if (coord.lat > maxLat) maxLat = coord.lat;
    if (coord.lng < minLng) minLng = coord.lng;
    if (coord.lng > maxLng) maxLng = coord.lng;
  });

  // NE and SW corners
  const ne = { lat: maxLat, lng: maxLng };
  const sw = { lat: minLat, lng: minLng };
  return { ne, sw };
};

function lerp(x: number, y: number, t: number) {
  return x + t * (y - x);
}

export function clamp(x: number, min: number, max: number): number {
  return Math.min(Math.max(x, min), max);
}

function createPalette(hexColors: string[]): { r: number; g: number; b: number }[] {
  // Map each hex color into an RGB value.
  const rgb = hexColors.map(colorToRGB);
  // Create a palette with 256 colors derived from our rgb colors.
  const size = 256;
  const step = (rgb.length - 1) / (size - 1);
  return Array(size)
    .fill(0)
    .map((_, i) => {
      // Get the lower and upper indices for each color.
      const index = i * step;
      const lower = Math.floor(index);
      const upper = Math.ceil(index);
      // Interpolate between the colors to get the shades.
      return {
        r: lerp(rgb[lower].r, rgb[upper].r, index - lower),
        g: lerp(rgb[lower].g, rgb[upper].g, index - lower),
        b: lerp(rgb[lower].b, rgb[upper].b, index - lower)
      };
    });
}

export function normalize(x: number, max: number = 1, min: number = 0): number {
  const y = (x - min) / (max - min);
  return clamp(y, 0, 1);
}

export function colorToRGB(color: string): { r: number; g: number; b: number } {
  const hex = color.startsWith('#') ? color.slice(1) : color;
  return {
    r: parseInt(hex.substring(0, 2), 16),
    g: parseInt(hex.substring(2, 4), 16),
    b: parseInt(hex.substring(4, 6), 16)
  };
}

export function renderRGB(rgb: GeoTiff, mask?: GeoTiff): HTMLCanvasElement {
  // Create an HTML canvas to draw the image.
  // https://www.w3schools.com/tags/canvas_createimagedata.asp
  const canvas = document.createElement('canvas');

  // Set the canvas size to the mask size if it's available,
  // otherwise set it to the RGB data layer size.
  canvas.width = mask ? mask.width : rgb.width;
  canvas.height = mask ? mask.height : rgb.height;

  // Since the mask size can be different than the RGB data layer size,
  // we calculate the "delta" between the RGB layer size and the canvas/mask
  // size. For example, if the RGB layer size is the same as the canvas size,
  // the delta is 1. If the RGB layer size is smaller than the canvas size,
  // the delta would be greater than 1.
  // This is used to translate the index from the canvas to the RGB layer.
  const dw = rgb.width / canvas.width;
  const dh = rgb.height / canvas.height;

  // Get the canvas image data buffer.
  const ctx = canvas.getContext('2d')!;
  const img = ctx.getImageData(0, 0, canvas.width, canvas.height);

  // Fill in every pixel in the canvas with the corresponding RGB layer value.
  // Since Javascript doesn't support multidimensional arrays or tensors,
  // everything is stored in flat arrays and we have to keep track of the
  // indices for each row and column ourselves.
  for (let y = 0; y < canvas.height; y++) {
    for (let x = 0; x < canvas.width; x++) {
      // RGB index keeps track of the RGB layer position.
      // This is multiplied by the deltas since it might be a different
      // size than the image size.
      const rgbIdx = Math.floor(y * dh) * rgb.width + Math.floor(x * dw);
      // Mask index keeps track of the mask layer position.
      const maskIdx = y * canvas.width + x;

      // Image index keeps track of the canvas image position.
      // HTML canvas expects a flat array with consecutive RGBA values.
      // Each value in the image buffer must be between 0 and 255.
      // The Alpha value is the transparency of that pixel,
      // if a mask was not provided, we default to 255 which is opaque.
      const imgIdx = y * canvas.width * 4 + x * 4;
      img.data[imgIdx + 0] = rgb.rasters[0][rgbIdx]; // Red
      img.data[imgIdx + 1] = rgb.rasters[1][rgbIdx]; // Green
      img.data[imgIdx + 2] = rgb.rasters[2][rgbIdx]; // Blue
      img.data[imgIdx + 3] = mask // Alpha
        ? mask.rasters[0][maskIdx] * 255
        : 255;
    }
  }

  // Draw the image data buffer into the canvas context.
  ctx.putImageData(img, 0, 0);
  return canvas;
}

export const renderPalette = async ({
  data,
  mask,
  colors,
  min,
  max,
  month = 0
}: {
  data: GeoTiff;
  mask: GeoTiff | undefined;
  colors: Array<string>;
  min: number;
  max: number;
  month?: number;
}) => {
  // First create a palette from a list of hex colors.
  const palette = createPalette(colors ?? ['000000', 'ffffff']);
  // Normalize each value of our raster/band of interest into indices,
  // such that they always map into a value within the palette.
  const indices = data.rasters[month]
    .map((x) => normalize(x, max ?? 1, min ?? 0))
    .map((x) => Math.round(x * (palette.length - 1)));

  return renderRGB(
    {
      ...data,
      // Map each index into the corresponding RGB values.
      rasters: [
        indices.map((i: number) => palette[i].r),
        indices.map((i: number) => palette[i].g),
        indices.map((i: number) => palette[i].b)
      ]
    },
    mask
  );
};
