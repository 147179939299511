import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { Params } from './types';
import { queryToSearch, searchToQuery } from './utils';

import { Query } from '@app-types/api';
import { AnyRecord } from '@app-types/general';
import { getFlattenJson } from '@utils/general';

export interface UseRouterReturn<Q extends Query = Query> {
  pushRoute: (
    route: string,
    query?: AnyRecord,
    options?: {
      timeout?: number;
      replace?: boolean;
    }
  ) => void;
  onBack: () => void;
  queryToSearch: (query: Q) => void;
  pathname: string;
  query: Query;
  search: string;
  params: Params;
  //
  onChangeQuery: (
    partialQuery: Partial<Q>,
    options?: {
      timeout?: number;
      replaceAll?: boolean;
    }
  ) => void;
}

export const useRouter = <Q extends Query = Query>(): UseRouterReturn<Q> => {
  const navigate = useNavigate();
  const { pathname, search } = useLocation();

  const params = useParams() as Params;

  const query = searchToQuery(search.slice(1)) as Query;

  const onChangeQuery: UseRouterReturn['onChangeQuery'] = (newQuery, options) => {
    const { timeout, replaceAll } = options || {};
    const handle = () => {
      const updatedQuery = replaceAll
        ? getFlattenJson(newQuery)
        : getFlattenJson({ ...query, ...newQuery });

      navigate({
        pathname: pathname,
        search: queryToSearch(updatedQuery)
      });
    };

    if (timeout) {
      setTimeout(handle, timeout);
      return;
    }
    handle();
  };

  return {
    params,
    search,
    queryToSearch,
    onBack: () => navigate(-1),
    query,
    onChangeQuery,
    pathname,
    pushRoute: (pathname, query, options) => {
      const { timeout, replace } = options || {};

      const handle = () => {
        navigate(`${pathname}${query ? `?${queryToSearch(query)}` : ''}`, {
          replace
        });
      };

      if (timeout) {
        setTimeout(handle, timeout);
        return;
      }
      handle();
    }
  };
};
