import { GoogleMap, Marker, PolygonF, Polyline, useJsApiLoader } from '@react-google-maps/api';
import { useEffect, useRef } from 'react';

import { defaultCenter, defaultZoom, googleMapsApiKey, libraries } from './utils';

import { AnyRecord, ChildrenProp, Nullable, StyleProps } from '@app-types/general';
import { Coordinates, MarkerCoordinates } from '@app-types/map';
import { compact } from '@utils/general';

/**
 * From Api Key
 */

interface MapProps extends StyleProps, ChildrenProp {
  center?: Coordinates;
  zoom?: number;
  onLoaded?: () => void;
  //
  markers?: Array<Nullable<MarkerCoordinates>>;
  onDragEndMarker?: (args: { index: number; lat: number; lng: number }) => void;
  //
  polygonPaths?: Array<Array<Coordinates>>;
  polyline?: Array<Coordinates>;
  //
  onClick?: (e: google.maps.MapMouseEvent) => void;
  onDblClick?: (e: google.maps.MapMouseEvent) => void;
  mapOptions?: google.maps.MapOptions;
}

export const Map = ({
  center = defaultCenter,
  zoom = defaultZoom,
  //
  markers: markersProps,
  onDragEndMarker,
  className,
  onLoaded,
  onClick,
  onDblClick,
  polygonPaths,
  polyline,
  children,
  mapOptions = {}
}: MapProps) => {
  const markers = markersProps && compact(markersProps);

  const refMap = useRef<google.maps.Map>();

  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey,
    libraries
  });

  useEffect(() => {
    if (isLoaded) {
      onLoaded?.();
    }
  }, [isLoaded]);

  useEffect(() => {
    refMap.current?.setOptions(mapOptions);
  }, [JSON.stringify(mapOptions)]);

  if (!isLoaded) {
    return null;
  }

  return (
    <div className={className}>
      <GoogleMap
        mapContainerStyle={{
          width: '100%',
          height: '100%'
        }}
        tilt={0}
        center={center}
        mapTypeId="satellite"
        zoom={zoom}
        onLoad={(map) => {
          refMap.current = map;
          const bounds = new window.google.maps.LatLngBounds(center);
          map.fitBounds(bounds);
        }}
        onClick={onClick}
        onDblClick={onDblClick}
      >
        {children}
        {markers?.map(({ lat, lng, draggable }, index) => (
          <Marker
            key={index}
            position={{ lat, lng }}
            draggable={draggable}
            onDragEnd={(e: AnyRecord) => {
              onDragEndMarker?.({ index, lat: e.latLng.lat(), lng: e.latLng.lng() });
            }}
          />
        ))}

        {polygonPaths?.map((polygonPath, index) => (
          <PolygonF
            key={index}
            path={polygonPath}
            options={{
              strokeColor: '#FF0000',
              strokeOpacity: 0.8,
              strokeWeight: 2,
              fillColor: 'red',
              fillOpacity: 0.35
            }}
          />
        ))}

        {polyline && (
          <Polyline
            path={polyline}
            options={{
              strokeColor: '#FF0000',
              strokeOpacity: 1.0,
              strokeWeight: 2
            }}
          />
        )}
      </GoogleMap>
    </div>
  );
};
