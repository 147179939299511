import { ButtonSvg } from '@components/button';
import { IconButton } from '@components/icon-button';
import { Tooltip } from '@components/tooltip';

import { StyleProps } from '@app-types/general';
import SvgQuestionCircle from '@icons/QuestionCircle';

export interface ButtonDescriptionProps extends StyleProps {
  description: React.ReactNode;
  svg?: ButtonSvg;
}

export const ButtonDescription = ({ description, className, svg }: ButtonDescriptionProps) => {
  return (
    <Tooltip content={description} className={className}>
      <IconButton svg={svg || SvgQuestionCircle} />
    </Tooltip>
  );
};
