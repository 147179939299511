import { StyleProps } from '@app-types/general';
import { cn } from '@utils/general';

export interface TableRowProps extends StyleProps {
  nodes: Array<React.ReactNode>;
  onClick?: () => void;
}

export const TableRow = ({
  nodes,
  onClick,
  className,
  index
}: TableRowProps & { index: number }) => {
  const oddRow = index % 2 !== 0;

  return (
    <tr
      onClick={onClick}
      className={cn(
        {
          ['cursor-pointer']: !!onClick
        },
        className
      )}
    >
      {nodes.map((node, indexCell) => {
        return (
          <td
            key={indexCell}
            className={cn('p-2 whitespace-no-wrap relative', {
              'bg-gray-100': oddRow
            })}
          >
            {node}
          </td>
        );
      })}
    </tr>
  );
};
