import { useState } from 'react';

import { LeftSide } from './LeftSide';
import { RightSide } from './RightSide';

interface StateData {
  sliderValue: number;
  getReportSlider: number;
  getReportParameter: number;
  selectedCoolingType: string;
  selectedHeatingType: string;
  selectedEnergySupplierType: string;
  predictions: Array<{
    address: string;
    placeId: string;
  }>;
  mapCenter: { lat: number; lng: number };
  address: string;
  city: string;
  state: string;
  zip: string;
}

export const SolarDesign = () => {
  const [stateData, setStateData] = useState<StateData>({
    sliderValue: 80,
    getReportSlider: 0,
    getReportParameter: 0,
    selectedCoolingType: '',
    selectedHeatingType: '',
    selectedEnergySupplierType: '',
    predictions: [],
    mapCenter: { lat: 0, lng: 0 },
    address: '',
    city: '',
    state: '',
    zip: ''
  });

  return (
    <div className="px-32">
      {/* <div className="flex flex-col w-full">
        <div className="breadCrumb mt-5">
          <ul>
            <li>Basics</li>
            <span className="Icon">
              {' '}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="7"
                height="12"
                fill="none"
                viewBox="0 0 7 12"
              >
                <path
                  fill="#A4B0C2"
                  d="M.914 0L0 .903l.457.45L5.17 6 .457 10.646l-.457.45.914.904.457-.453L7 6 1.37.453.915 0z"
                ></path>
              </svg>
            </span>
            <li>Panels</li>
            <span className="Icon">
              {' '}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="7"
                height="12"
                fill="none"
                viewBox="0 0 7 12"
              >
                <path
                  fill="#A4B0C2"
                  d="M.914 0L0 .903l.457.45L5.17 6 .457 10.646l-.457.45.914.904.457-.453L7 6 1.37.453.915 0z"
                ></path>
              </svg>
            </span>
            <li>Permit</li>
            <span className="Icon">
              {' '}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="7"
                height="12"
                fill="none"
                viewBox="0 0 7 12"
              >
                <path
                  fill="#A4B0C2"
                  d="M.914 0L0 .903l.457.45L5.17 6 .457 10.646l-.457.45.914.904.457-.453L7 6 1.37.453.915 0z"
                ></path>
              </svg>
            </span>
            <li>Billing/Financing</li>
          </ul>
        </div>
      </div> */}
      <div className="flex gap-8">
        <LeftSide
          onSliderValueChange={(sliderValue: number) => {
            setStateData((prevState) => ({
              ...prevState,
              sliderValue
            }));
          }}
          getReportSlider={stateData.getReportSlider}
          getReportParameter={stateData.getReportParameter}
          className="w-1/3"
        />
        <RightSide />
      </div>
    </div>
  );
};
