import { useForm } from './useForm';

import { deepJsonCopy, get, set } from '@utils/general';

//eslint-disable-next-line
export const useFormField = <Value extends any = any>(args: {
  name?: string;
}): {
  error?: string;
  getNestedFieldName: (fieldName: string) => string;
  setError: (error: string) => void;
  field: {
    value?: Value;
    name?: string;
    onClick: () => void;
    onChange: (e: { target: { name?: string; value: Value } }) => void;
  };
} => {
  const { name } = args;
  const { setValue, value, errors, setTouched, setErrors } = useForm();

  return {
    getNestedFieldName: (fieldName) => `${name}.${fieldName.toString()}`,
    error: name ? errors[name] : undefined,
    setError: (error) => {
      if (!name) return;

      setErrors((errors) => {
        const newErrors = deepJsonCopy(errors);
        newErrors[name] = error;
        return newErrors;
      });
    },
    field: {
      value: name ? get(value, name) : undefined,
      name,

      onChange: (e) => {
        if (!name) return;

        setValue((state) => {
          const newState = deepJsonCopy(state);
          set(newState, name, e.target.value);
          return newState;
        });
      },
      onClick: () => {
        if (!name) return;

        setTouched((touched) => ({ ...touched, [name]: true }));
      }
    }
  };
};
