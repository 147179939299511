import { cloneElement, forwardRef, isValidElement, ReactElement } from 'react';
import { ThreeDots } from 'react-loader-spinner';

import { FormuxDataContainer } from './FormuxDataContainer';

import { StyleProps } from '@app-types/general';
import { cn } from '@utils/general';

const primaryStyles = 'bg-sky-700 text-white fill-white hover:bg-indigo-500 hover:bg-sky-800';

const errorStyles = 'bg-red-600 text-white fill-white  hover:bg-red-500';

const outlinedStyles =
  'bg-transparent text-gray-600 fill-gray-600 ring-2 ring-gray-300 hover:bg-gray-100';

const successStyles = 'bg-lime-500 text-black fill-black fill-white hover:bg-lime-600';
const linkStyles = 'shadow-none text-blue-500 hover:text-blue-600 underline';

export type ButtonSvg = React.FunctionComponent<StyleProps> | React.ReactElement<StyleProps>;

export interface ButtonProps
  extends Omit<React.ButtonHTMLAttributes<HTMLButtonElement>, 'onClick'> {
  variant?: 'primary' | 'outlined' | 'error' | 'success' | 'link';
  svgPosition?: 'left' | 'right';
  label?: React.ReactNode;
  isBusy?: boolean;
  svg?: ButtonSvg;
  stopPropagation?: boolean;
  preventDefault?: boolean;
  onClick?: (e: MouseEvent) => void;
  as?: 'div';
  formuxSubmit?: boolean;
  narrow?: boolean;
  rounded?: boolean;
}

//@ts-expect-error ignore types
const ButtonElement = (props) => <button {...props} />;
//@ts-expect-error ignore types
const DivElement = (props) => <div {...props} />;

export const Button = forwardRef<HTMLButtonElement, ButtonProps>((props, ref) => {
  const {
    className,
    variant = 'primary',
    label,
    isBusy,
    disabled: disabledProp,
    svg,
    stopPropagation,
    preventDefault,
    onClick,
    svgPosition = 'left',
    as,
    formuxSubmit,
    narrow,
    rounded,
    ...omittedProps
  } = props;

  const disabled = disabledProp || isBusy;

  const getSvg = (Component: ButtonSvg): ReactElement => {
    const commonClassName = cn('h-5 w-5', {
      ['mr-2']: svgPosition === 'left' && label,
      ['ml-2']: svgPosition === 'right' && label
    });

    if (isValidElement(Component)) {
      return cloneElement(Component, {
        className: cn(commonClassName, Component.props?.className || '')
      });
    }

    return <Component className={commonClassName} />;
  };

  const Component = as === 'div' ? DivElement : ButtonElement;

  const spinner = (
    <div className="absolute top-0 h-full flex items-center">
      <ThreeDots height="1rem" color="gray" />
    </div>
  );

  if (formuxSubmit) {
    return (
      <FormuxDataContainer>
        {({ hasChange, isValid, errorMode, setErrorMode }) => {
          const buttonDisabled = disabled || !isValid || !hasChange;

          return (
            <Component
              ref={ref}
              className={cn(
                'relative px-3 py-1.5 text-sm shadow-sm font-semibold rounded-md flex items-center justify-center leading-6 whitespace-nowrap h-fit',
                {
                  [primaryStyles]: variant === 'primary',
                  [outlinedStyles]: variant === 'outlined',
                  [errorStyles]: variant === 'error',
                  [successStyles]: variant === 'success',
                  [linkStyles]: variant === 'link',
                  ['cursor-not-allowed']: buttonDisabled,
                  '!rounded-3xl': rounded,
                  ['!bg-indigo-300']: variant === 'primary' && buttonDisabled,
                  ['!bg-gray-300']: variant === 'outlined' && buttonDisabled,
                  ['!bg-red-300']: variant === 'error' && buttonDisabled,
                  ['!px-2 !py-0 !rounded-2xl w-fit']: narrow
                },
                className
              )}
              onClick={(e: MouseEvent) => {
                if (stopPropagation) {
                  e.stopPropagation();
                }

                if (preventDefault) {
                  e.preventDefault();
                }

                /**
                 * Cuando se monta el formulario el mode de error es 'touched' mostrando los errores de validacion en los fields que has sido "tocados"
                 * Pero una vez hecho click en el boton de submit se mustran los restantes erres aunque no hayan sido tocados los fields
                 */
                if (errorMode === 'touched') {
                  setErrorMode('all');
                }

                if (buttonDisabled) {
                  return;
                }

                onClick?.(e);
              }}
              {...omittedProps}
            >
              {svg && svgPosition === 'left' && getSvg(svg)}
              {label}
              {svg && svgPosition === 'right' && getSvg(svg)}

              {isBusy && spinner}
            </Component>
          );
        }}
      </FormuxDataContainer>
    );
  }

  return (
    <Component
      ref={ref}
      className={cn(
        'relative px-3 py-1.5 text-sm shadow-md font-semibold rounded-md flex items-center justify-center leading-6 whitespace-nowrap h-fit',
        {
          [primaryStyles]: variant === 'primary',
          [outlinedStyles]: variant === 'outlined',
          [errorStyles]: variant === 'error',
          [successStyles]: variant === 'success',
          [linkStyles]: variant === 'link',
          ['cursor-not-allowed']: disabled,
          '!rounded-3xl': rounded,
          ['!bg-indigo-300']: variant === 'primary' && disabled,
          ['!bg-gray-300']: variant === 'outlined' && disabled,
          ['!bg-red-300']: variant === 'error' && disabled,
          ['!px-2 !py-0 !rounded-2xl w-fit']: narrow
        },
        className
      )}
      onClick={(e: MouseEvent) => {
        if (stopPropagation) {
          e.stopPropagation();
        }

        if (preventDefault) {
          e.preventDefault();
        }

        if (disabled) {
          return;
        }

        onClick?.(e);
      }}
      {...omittedProps}
    >
      {svg && svgPosition === 'left' && getSvg(svg)}
      {label}
      {svg && svgPosition === 'right' && getSvg(svg)}

      {isBusy && spinner}
    </Component>
  );
});
