import { PolygonF, Polyline } from '@react-google-maps/api';

import { Coordinates } from '@app-types/map';

export const defaultCenter: Coordinates = { lat: 40.688386661759274, lng: -73.93344666237486 };
export const getBoundingBox = (coords: Array<Coordinates>) => {
  const lats = coords.map((coord) => coord.lat);
  const lngs = coords.map((coord) => coord.lng);
  return {
    minLat: Math.min(...lats),
    maxLat: Math.max(...lats),
    minLng: Math.min(...lngs),
    maxLng: Math.max(...lngs)
  };
};

export const renderPanels = (panelsCoordinates: Array<Array<Coordinates>>) => {
  return panelsCoordinates.map((polygonPath, index) => {
    return (
      <PolygonF
        key={index}
        path={polygonPath}
        options={{
          strokeColor: '#000',
          strokeOpacity: 0.8,
          strokeWeight: 2,
          fillColor: 'gray',
          fillOpacity: 0.35
        }}
      />
    );
  });
};

export const renderRidgeLine = (ridgeLine: Array<Coordinates>) => {
  return (
    <Polyline
      path={ridgeLine}
      options={{
        strokeColor: '#FF0000',
        strokeOpacity: 1.0,
        strokeWeight: 2
      }}
    />
  );
};

export const renderSelectingAreaMarkers = (selectingAreaMarkers: Array<Coordinates>) => {
  return (
    <Polyline
      path={selectingAreaMarkers}
      options={{
        strokeColor: '#FF0000',
        strokeOpacity: 1.0,
        strokeWeight: 2
      }}
    />
  );
};

export const renderSelectedArea = (selectedArea: Array<Coordinates>) => {
  return (
    <PolygonF
      path={selectedArea}
      options={{
        strokeColor: '#FF0000',
        strokeOpacity: 0.8,
        strokeWeight: 2,
        fillColor: 'red',
        fillOpacity: 0.35
      }}
    />
  );
};
