import { ButtonClose } from '@components/button-close';

import { useModalPage } from '@hooks/useModalPage';
import { usePortal } from '@hooks/usePortal';

import { dynamic } from '@utils/makeLazy';

//eslint-disable-next-line
const Component = dynamic(() => import('./Component').then((m) => m));

export const useAddUpdateComponentModal = () => {
  return {
    addUpdateComponentModal: useModalPage<{
      componentId?: number;
      onAfterSuccess?: () => void;
    } | void>((args) => ({
      useProps: () => {
        const { componentId, onAfterSuccess } = args || {};
        const portal = usePortal();

        const title = componentId ? 'Please modify the component' : 'Please insert a component';

        return {
          title,
          content: (
            <Component portal={portal} componentId={componentId} onAfterSuccess={onAfterSuccess} />
          ),
          closeButton: <ButtonClose />,
          primaryBtn: <div ref={portal.ref} />,
          className: '!w-[55rem]'
        };
      }
    }))
  };
};
