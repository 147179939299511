import graph from '@assets/graphs.png';

import { TwoSideContainer } from './TwoSideContainer';

export const Section8 = () => {
  return (
    <section className="bg-stone-800 flex flex-col items-center">
      <TwoSideContainer
        leftContent={
          <div className="flex flex-col gap-4 text-white text-2xl font-bold">
            <h4 className="head12">
              {' '}
              To get a rough idea of the tradeoffs in cost our platform will take your input and and
              instantly compute a rough estimate.{' '}
            </h4>
            <h4 className="head12">
              The ROI is computed for two cases: one without any tax benefits, the other with the
              current government incentives.{' '}
            </h4>
          </div>
        }
        rightContent={<img src={graph} alt="all in one" className="size-96" />}
      />

      <h3 className="mt-3 text-3xl text-lime-300 font-bold my-10 px-3 text-center">
        You can expext to pay 30 to 40 percent less for your solar system.
      </h3>
    </section>
  );
};
