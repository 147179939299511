import { PersistentUtils } from '@features/persistent/types';

import type { EnhancedStore } from '@reduxjs/toolkit';

export const backdoor: {
  store: {
    dispatch: EnhancedStore['dispatch'];
    getState: EnhancedStore['getState'];
    subscribe: EnhancedStore['subscribe'];
  };
  persistent: PersistentUtils;
} = {
  persistent: {
    setPersistent: async () => {
      console.log('calling default setPersistent');
    },
    getPersistent: async () => {
      console.log('calling default getPersistent');
    },
    removePersistent: async () => {
      console.log('calling default removePersistent');
    }
  },
  store: {
    dispatch: (action) => action,
    getState: () => ({}),
    subscribe: () => () => undefined
  }
};
