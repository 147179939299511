import { ButtonClose } from '@components/button-close';

import { useModalPage } from '@hooks/useModalPage';

import { AppComponent } from '@app-types/general';
import { dynamic } from '@utils/makeLazy';

//eslint-disable-next-line
const Component = dynamic(() => import('./Component').then((m) => m));

export const useComponentDetailsModal = () => {
  return {
    componentDetailsModal: useModalPage<{
      component: AppComponent;
    } | void>((args) => ({
      useProps: () => {
        const { component } = args || {};

        return {
          title: 'Component Details',
          content: <Component component={component} />,
          closeButton: <ButtonClose />,
          className: '!w-[30rem]'
        };
      }
    }))
  };
};
