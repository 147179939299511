import { ButtonClose } from '@components/button-close';

import { useModalPage } from '@hooks/useModalPage';
import { usePortal } from '@hooks/usePortal';

import { SolarDesign } from '@app-types/general';
import { dynamic } from '@utils/makeLazy';

//eslint-disable-next-line
const Component = dynamic(() => import('./Component').then((m) => m));

export const useAddUpdateDesingModal = () => {
  return {
    addUpdateDesingModal: useModalPage<{
      design?: SolarDesign;
      onAfterSuccess?: () => void;
    } | void>((args) => ({
      useProps: () => {
        const { design, onAfterSuccess } = args || {};
        const portal = usePortal();

        const title = design
          ? 'Please modify the information of the selected design type'
          : 'Please add the information of the new design type';

        return {
          title,
          content: <Component portal={portal} design={design} onAfterSuccess={onAfterSuccess} />,
          closeButton: <ButtonClose />,
          primaryBtn: <div ref={portal.ref} />,
          className: '!w-[90vh]'
        };
      }
    }))
  };
};
