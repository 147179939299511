import { Route, Routes } from 'react-router-dom';

import { Footer } from '@components/footer';

import { Navbar } from '@pages/@common/nav-bar';
import { Admin } from '@pages/admin-page';
import { ForgetPassword } from '@pages/forget-password';
import { Home } from '@pages/home';
import { MapPage } from '@pages/map-page';
import { NotFound } from '@pages/not-found';
import { PasswordRecovery } from '@pages/password-recovery';
import { PrivacyPolicy } from '@pages/privacy-policy';
import { SingIn } from '@pages/sign-in';
import { SignUp } from '@pages/sign-up';
import { SolarDesign } from '@pages/solar-design';
import { TermsAndConditions } from '@pages/term-condition';

import { withAutorized } from '@utils/hoc/withAutorized';
import { withPageProviders } from '@utils/hoc/withPageProviders';

const AutorizedAdmins = withAutorized(['Admin', 'SuperAdmin']);

export const App = () => {
  return (
    <>
      <Navbar />
      <Routes>
        <Route path="/" element={<Home />} />

        <Route path="/signup" element={<SignUp />} />

        <Route path="/terms_condition" element={<TermsAndConditions />} />

        <Route path="/privacy_policy" element={<PrivacyPolicy />} />

        <Route path="/login" element={<SingIn />} />

        <Route path="/solar_design" element={<SolarDesign />} />

        <Route path="/forgetpassword" element={<ForgetPassword />} />

        <Route path="/passwordrecovery" element={<PasswordRecovery />} />

        <Route path="/map" element={<MapPage />} />

        <Route path="/admin" element={withPageProviders(<Admin />, AutorizedAdmins)} />

        <Route path="*" element={<NotFound />} />
      </Routes>
      <Footer />
    </>
  );
};
