import { useFetch } from '@hooks/useFetch';

import { FetchResource } from '@app-types/api';
import { getEndpoint } from '@utils/api';

export const useAuthSignUp = (): {
  authSignUp: FetchResource<
    { firstName: string; lastName: string; email: string; PhoneNumber: string; password: string },
    void
  >;
} => {
  const fetch = useFetch();

  return {
    authSignUp: {
      data: fetch[0],
      status: fetch[1],
      fetch: ({ PhoneNumber, email, firstName, lastName, password }, options = {}) => {
        fetch[2](
          {
            method: 'post',
            url: getEndpoint({ path: '/account/signup' }),
            data: { firstName, lastName, email, PhoneNumber, password }
          },
          options
        );
      },
      reset: fetch[3]
    }
  };
};
