export interface Coordinates {
  lat: number;
  lng: number;
}

export interface MarkerCoordinates extends Coordinates {
  draggable?: boolean;
  icon?: google.maps.Icon;
}

export type Bounds = google.maps.LatLngBoundsLiteral;

export interface GeoTiff {
  rasters: Array<Array<number>>;
  width: number;
  height: number;
  bounds?: Bounds;
}

export enum PanelOrientation {
  PORTRAIT = 0,
  LANDSCAPE = 1
}

export interface MapStateIrradianceMapData {
  bounds?: Bounds;
  url?: string;
  dataLayer?: GetDataLayerResponse;
  month?: number;
}

export interface MapState {
  isLoaded: boolean;
  searchedLocation: Coordinates | undefined;
  //
  selectingArea: boolean;
  selectingAreaMarkers: Array<MarkerCoordinates>;
  selectedArea: Array<Coordinates>;
  //
  selectingRidgeLine: boolean;
  ridgeLinePoints: Array<Coordinates>;
  panelFeatureState: {
    panelsAmount: number | undefined;
    panelsModelId: number | undefined;
    panelsSeparation: number | undefined;
    panelsOrientation: PanelOrientation | undefined;
  };
  panels: Array<Array<Coordinates>>;
  mapOptions: google.maps.MapOptions;
  //
  irradianceMapData: MapStateIrradianceMapData;
}

export interface GetDataLayerResponse {
  imageryDate: {
    year: number;
    month: number;
    day: number;
  };
  imageryProcessedDate: {
    year: number;
    month: number;
    day: number;
  };
  dsmUrl: string;
  rgbUrl: string;
  maskUrl: string;
  annualFluxUrl: string;
  monthlyFluxUrl: string;
  hourlyShadeUrls: [
    string,
    string,
    string,
    string,
    string,
    string,
    string,
    string,
    string,
    string,
    string,
    string
  ];
  imageryQuality: 'HIGH';
}
