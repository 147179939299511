import { Libraries } from '@react-google-maps/api';

import { Coordinates } from '@app-types/map';

export const googleMapsApiKey = 'AIzaSyB5fF9C_VlMQmTOIrK4_bgCzwEukEHqsuE';

export const defaultCenter: Coordinates = {
  lat: -3.745,
  lng: -38.523
};

export const defaultZoom = 10;

export const libraries: Libraries = ['places', 'geometry'];
