import { useFetch } from '@hooks/useFetch';

import { FetchResource } from '@app-types/api';
import { AnyRecord } from '@app-types/general';
import { getEndpoint } from '@utils/api';

export const useGetGeolocationAutocomplete = (): {
  getGeolocationAutocomplete: FetchResource<{ searchText: string }, AnyRecord>;
} => {
  const fetch = useFetch<AnyRecord>();

  return {
    getGeolocationAutocomplete: {
      data: fetch[0],
      status: fetch[1],
      fetch: ({ searchText }, options = {}) => {
        fetch[2](
          {
            method: 'get',
            url: getEndpoint({ path: '/geolocation/autocomplete', query: { searchText } })
          },
          options
        );
      },
      reset: fetch[3]
    }
  };
};
