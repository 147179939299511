import { useFetch } from '@hooks/useFetch';

import { FetchResource } from '@app-types/api';
import { getEndpoint } from '@utils/api';

export const useAuthResetPassword = (): {
  authResetPassword: FetchResource<{ password: string; email: string; token: string }, void>;
} => {
  const fetch = useFetch();

  return {
    authResetPassword: {
      data: fetch[0],
      status: fetch[1],
      fetch: ({ password, email, token }, options = {}) => {
        fetch[2](
          {
            method: 'post',
            url: getEndpoint({ path: '/account/reset-password' }),
            data: { email, password, token }
          },
          options
        );
      },
      reset: fetch[3]
    }
  };
};
