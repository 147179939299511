import { forwardRef, useEffect, useRef } from 'react';

import { FormFieldWrapper, FormFieldWrapperProps } from '@components/form-field-wrapper';
import { useFormField } from '@components/formux/useFormField';
import { Input, InputProps } from '@components/input';

import { Nullable } from '@app-types/general';
import { cn, isNullOrUndefined, mergeRefs } from '@utils/general';

export interface FieldInputProps extends InputProps, FormFieldWrapperProps {
  error?: Nullable<string>;
  autoFocusDelay?: number;
}

export const FieldInput = forwardRef<HTMLInputElement, FieldInputProps>((props, ref) => {
  const {
    className,
    label,
    description,
    error: errorProp,
    autoFocus,
    autoFocusDelay = 0,
    prefix,
    ...omittedProps
  } = props;

  const refInternal = useRef<HTMLInputElement>(null);
  const { field, error } = useFormField(props);

  const { value, onChange } = field;

  const getOnChange = () => (event: React.ChangeEvent<HTMLInputElement>) => {
    if (prefix) {
      /**
       * add prefix
       */
      return onChange({
        target: {
          name: field.name,
          value: isNullOrUndefined(event.target.value) ? prefix : `${prefix}${event.target.value}`
        }
      });
    }

    onChange(event);
  };

  const getValue = () => {
    let newValue = isNullOrUndefined(value) ? '' : value;

    if (prefix && newValue.includes(prefix)) {
      /**
       * remove prefix
       */
      newValue = newValue.replace(prefix, '');
    }

    return newValue;
  };

  useEffect(() => {
    if (autoFocus) {
      setTimeout(() => refInternal.current?.focus(), autoFocusDelay);
    }
  }, []);

  return (
    <FormFieldWrapper
      label={label}
      error={errorProp ?? error}
      description={description}
      className={className}
    >
      <Input
        ref={mergeRefs([refInternal, ref])}
        className={cn({
          'ring-1 rounded-md ring-red-500 focus:ring-red-500': !!error
        })}
        {...omittedProps}
        {...field}
        value={getValue()}
        onChange={getOnChange()}
      />
    </FormFieldWrapper>
  );
});
