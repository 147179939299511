export const getLabelWithDescription = (label: string, description: string) => {
  return (
    <div>
      {label} <span className="text-xs">({description})</span>
    </div>
  );
};

export const getRequiredLabel = (label: string): string => `${label} *`;

export const truncateStr = (str: string, maxLength: number) => {
  if (str.length > maxLength) {
    return `${str.substring(0, maxLength)}...`;
  }
  return str;
};
