import { ChildrenProp, StyleProps } from '@app-types/general';
import { cn } from '@utils/general';

export interface SectionContainerProps extends StyleProps, ChildrenProp {
  label: string;
  children: React.ReactNode;
}

export const SectionContainer = ({ className, label, children }: SectionContainerProps) => {
  return (
    <div className={cn(className)}>
      <div className="text-xl mb-3">{label}</div>
      {children}
    </div>
  );
};
