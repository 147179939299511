import { useEffect, useState } from 'react';

import { getPersistentAuthData } from '@hooks/useAuth/utils';
import { useDebouncedValue } from '@hooks/useDebouncedValue';

import {
  ApiError,
  ApiStatus,
  FetchData,
  FetchMethod,
  FetchOptions,
  FetchStatus,
  Headers
} from '@app-types/api';
import axios, { AxiosError } from 'axios';

export type FetchFnReset = () => void;

interface FetchFnCallArgs {
  method: FetchMethod;
  url: string;
  data?: any;
  headers?: Headers;
}

export type FetchFnCall<Data = unknown> = (
  args?: FetchFnCallArgs,
  options?: Omit<FetchOptions<Data>, 'fetchWhenMount'>
) => void;
export type UseFetchReturn<Data = unknown> = [
  FetchData<Data>,
  FetchStatus,
  FetchFnCall<Data>,
  FetchFnReset
];

export const useFetch = <Data = any>(): UseFetchReturn<Data> => {
  const [response, setResponse] = useState<FetchData<Data>>(null);
  const [error, setError] = useState<ApiError | null>(null);
  const [status, setStatus] = useState<ApiStatus>('NOT_STARTED');
  const [wasCalled, setWasCalled] = useState<boolean>(false);

  const debouncedStatus = useDebouncedValue<ApiStatus>(status, 100);

  useEffect(() => {
    if (debouncedStatus === 'SUCCESS') {
      setStatus('NOT_STARTED');
    }
  }, [debouncedStatus]);

  const handleReset = () => {
    setResponse(null);
    setError(null);
    setStatus('NOT_STARTED');
    setWasCalled(false);
  };

  const handleFetch: FetchFnCall<Data> = async (args, options) => {
    if (!args) throw new Error('Should set some fetch args');

    const { onAfterSuccess, onAfterFailed, onResponseProcessing, useNativeFetch } = options || {};

    const handleFailure = (message: string | undefined) => {
      const apiError: ApiError = {
        message: message || 'Something went wrong'
      };

      onAfterFailed?.(apiError);
      setResponse(null);
      setError(apiError);
      setStatus('FAILED');
      setWasCalled(true);
    };

    const handleSuccess = (result: Data, message: string) => {
      setResponse({
        message,
        result
      });

      onAfterSuccess?.({
        message,
        result
      });
      setStatus('SUCCESS');
      setWasCalled(true);
    };

    try {
      setStatus('BUSY');

      const { authData } = await getPersistentAuthData();
      const token = authData?.token;

      const { method, url, data, headers = {} } = args;

      let out = null;

      if (useNativeFetch) {
        //TODO
        out = await fetch(url);
      } else {
        out = (
          await axios({
            url,
            method,
            data,
            headers: {
              Authorization: token && `Bearer ${token}`,
              ...headers
            }
          })
        ).data;
      }

      let result = null;
      let message = '';

      if (out.result !== undefined && out.message !== undefined) {
        result = out.result;
        message = out.message;
      } else {
        result = out;
        message = '';
      }

      if (result === null || result === false) {
        /**
         * The api return result null or false when fail
         */
        return handleFailure(message);
      }

      if (onResponseProcessing) {
        const processed = await onResponseProcessing?.({ result, message });
        handleSuccess(processed.result, processed.message);
      } else {
        handleSuccess(result, message);
      }
    } catch (e) {
      const { response } = e as AxiosError<{ message?: string }>;

      handleFailure(response?.data?.message);
    }
  };

  return [
    response,
    {
      isNotStarted: status === 'NOT_STARTED',
      isBusy: status === 'BUSY',
      isFailed: status === 'FAILED',
      isSuccess: status === 'SUCCESS',
      error,
      wasCalled
    },
    handleFetch,
    handleReset
  ];
};
