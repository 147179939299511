import { ButtonClose } from '@components/button-close';

import { useModalPage } from '@hooks/useModalPage';

import { SolarDesign } from '@app-types/general';
import { dynamic } from '@utils/makeLazy';

//eslint-disable-next-line
const Component = dynamic(() => import('./Component').then((m) => m));

export const useDesingVersionsModal = () => {
  return {
    desingVersionsModal: useModalPage<{
      design: SolarDesign;
      onAfterSuccess?: () => void;
    } | void>((args) => ({
      useProps: () => {
        const { design, onAfterSuccess } = args || {};

        return {
          title: 'Desing Versions',
          content: <Component design={design} onAfterSuccess={onAfterSuccess} />,
          closeButton: <ButtonClose />,
          className: '!w-[60rem]'
        };
      }
    }))
  };
};
