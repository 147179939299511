import BMW from '@assets/BMW.png';
import Honda from '@assets/Honda.png';
import ForgetImg from '@assets/image 2.jpg';
import JaneCooper from '@assets/jane-cooper.png';
import Jeep from '@assets/Jeep.png';
import KIA from '@assets/KIA.png';
import Lexus from '@assets/Lexus.png';
import VideoImg from '@assets/login-video-poster.jpg';
import Logo from '@assets/logo.png';
import Mercedez from '@assets/Mercedez.png';
import Skoda from '@assets/Skoda.png';
import Wolksvagon from '@assets/Wolksvagon.png';

export const IMAGES = {
  BMW,
  Honda,
  Jeep,
  KIA,
  Lexus,
  Skoda,
  Wolksvagon,
  Mercedez,
  JaneCooper,
  Logo,
  VideoImg,
  ForgetImg
};
