import { Formux } from '@components/formux';

import { ChildrenProp } from '@app-types/general';
import { MapState } from '@app-types/map';
import { defaultPanelFeatureState } from '@utils/map';

export interface FormContextProps extends ChildrenProp {}

export const FormContext = ({ children }: FormContextProps) => {
  return (
    <Formux<MapState>
      value={{
        isLoaded: false,
        searchedLocation: undefined,
        selectingArea: false,
        panelFeatureState: defaultPanelFeatureState,
        selectingRidgeLine: false,
        ridgeLinePoints: [],
        panels: [],
        mapOptions: {},
        selectedArea: [],
        selectingAreaMarkers: [],
        irradianceMapData: {}
      }}
      validate={[
        {
          field: 'panelFeatureState.panelsModelId',
          type: 'required',
          message: 'Model is required'
        },
        {
          field: 'panelFeatureState.panelsAmount',
          type: 'required',
          message: 'Panels amount is required'
        },
        {
          field: 'panelFeatureState.panelsAmount',
          type: 'custom',
          customCb: (value) => value > 0,
          message: 'Panels amount must be greater than 0'
        },
        {
          field: 'panelFeatureState.panelsAmount',
          type: 'custom',
          customCb: (value) => value % 1 === 0,
          message: 'Panels amount must be an integer'
        },
        {
          field: 'panelFeatureState.panelsSeparation',
          type: 'required',
          message: 'Panels separation is required'
        },
        {
          field: 'panelFeatureState.panelsSeparation',
          type: 'custom',
          customCb: (value) => value > 0,
          message: 'Panels separation must be greater than 0'
        },
        {
          field: 'selectedArea',
          type: 'custom',
          customCb: (value) => value.length >= 3,
          message: 'Should selected the area'
        },
        {
          field: 'ridgeLinePoints',
          type: 'custom',
          customCb: (value) => value.length === 2,
          message: 'Should select the direction'
        }
      ]}
    >
      {() => children}
    </Formux>
  );
};
