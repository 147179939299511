import { HtmlTextContainer } from '@components/html-text-container';

import { OutlinedBox } from '@pages/@common/outlined-box';

import { cn } from '@utils/general';

export const Section5 = () => {
  const renderStep = ({
    step,
    position,
    content
  }: {
    step: number;
    position: 'right' | 'left';
    content: React.ReactNode;
  }) => {
    return (
      <div
        className={cn('flex items-center relative w-[100vw] max-w-[35rem]', {
          'justify-start': position === 'left',
          'justify-end': position === 'right'
        })}
      >
        <div className="absolute-centered flex flex-col items-center h-full">
          <div className="border-l border-gray-900 w-0 h-full" />
          <div className="absolute-centered bg-blue-600 flex items-center justify-center rounded-full size-14 text-white font-bold text-2xl">
            {step}
          </div>
        </div>
        <OutlinedBox
          className={cn('text-black font-bold w-[40%] text-xl', {
            'left-0 text-left': position === 'left',
            'right-0 text-right': position === 'right'
          })}
        >
          {content}
        </OutlinedBox>
      </div>
    );
  };

  return (
    <section className="flex flex-col items-center">
      <h2 className="text-5xl text-sky-700 font-bold my-10 text-center">How does this work?</h2>

      {renderStep({
        step: 1,
        content: <p>Tap on My Solar Design menu item</p>,
        position: 'left'
      })}
      {renderStep({
        step: 2,
        content: (
          <>
            <h4>Fill in the form with the basic information requested</h4>
            <p className="font-normal text-sky-900 mt-4">Your zip code, Energy consumed, etc</p>
          </>
        ),
        position: 'right'
      })}
      {renderStep({
        step: 3,
        content: (
          <HtmlTextContainer>
            <h4>Decide on a Battery Backup</h4>
            <ul className="font-normal marker:text-3xl marker:text-lime-600 text-sky-900">
              <li>I need only an All-in-one battery (Lithium)</li>
              <li>I'll add battery backup later</li>
              <li>I only need roof solar panels; no battery backup</li>
            </ul>
          </HtmlTextContainer>
        ),
        position: 'left'
      })}

      <div className="flex items-center relative w-[100vw] max-w-[35rem] h-20">
        <div className="absolute-centered h-full">
          <div className="border-l border-gray-900 w-0 h-1/2" />
          <div className="absolute-centered size-7 rounded-full bg-blue-600" />
        </div>
      </div>
    </section>
  );
};
