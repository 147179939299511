import { GroundOverlay } from '@react-google-maps/api';
import { useEffect, useState } from 'react';

import { Bounds } from '@app-types/map';

interface IrradianceMapProps {
  url?: string;
  bounds?: Bounds;
}

export const IrradianceMap = (props: IrradianceMapProps) => {
  const { url, bounds } = props;
  const [groundOverlayurl, setGroundOverlayurl] = useState<string>();

  useEffect(() => {
    if (url) {
      setGroundOverlayurl(undefined);
      setTimeout(() => setGroundOverlayurl(url), 10);
    }
  }, [url]);

  if (!groundOverlayurl || !bounds) return null;

  return (
    <GroundOverlay
      url={groundOverlayurl}
      bounds={bounds}
      options={{
        opacity: 0.5,
        clickable: false
      }}
    />
  );
};
