import { useFetch } from '@hooks/useFetch';

import { FetchResource } from '@app-types/api';
import { AnyRecord } from '@app-types/general';
import { getEndpoint } from '@utils/api';

export const useGetGeolocationGeocode = (): {
  getGeolocationGeocode: FetchResource<{ lon: number; lat: number; zipCode?: string }, AnyRecord>;
} => {
  const fetch = useFetch<AnyRecord>();

  return {
    getGeolocationGeocode: {
      data: fetch[0],
      status: fetch[1],
      fetch: ({ lat, lon, zipCode }, options = {}) => {
        fetch[2](
          {
            method: 'get',
            url: getEndpoint({ path: '/geolocation/geocode', query: { lat, lon, zipCode } })
          },
          options
        );
      },
      reset: fetch[3]
    }
  };
};
