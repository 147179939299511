import { createElement } from 'react';

import { StyleProps } from '@app-types/general';

export function SvgPhoneSolid(props: StyleProps) {
  return createElement(
    'svg',
    {
      viewBox: '0 0 32 32',
      width: '1em',
      height: '1em',
      ...props
    },
    createElement('path', {
      d: 'M8.656 3c-.523 0-1.039.188-1.469.531l-.062.031-.031.032-3.125 3.219.031.03a3.134 3.134 0 00-.844 3.376c.004.008-.004.023 0 .031.848 2.426 3.016 7.11 7.25 11.344 4.25 4.25 8.996 6.332 11.344 7.25h.031a3.59 3.59 0 003.469-.688L28.406 25c.828-.828.828-2.266 0-3.094l-4.062-4.062-.032-.063c-.828-.828-2.296-.828-3.125 0l-2 2a16.176 16.176 0 01-4.093-2.812c-1.637-1.563-2.473-3.36-2.781-4.063l2-2c.84-.84.855-2.238-.032-3.062l.031-.032-.093-.093-4-4.125-.031-.031-.063-.032A2.356 2.356 0 008.656 3zm0 2a.35.35 0 01.219.094l4 4.093.094.094c-.008-.008.058.098-.063.219l-2.5 2.5-.469.438.22.624s1.148 3.075 3.562 5.376l.219.187C16.261 20.746 19 21.906 19 21.906l.625.282 2.969-2.97c.172-.171.14-.171.312 0L27 23.314c.172.171.172.109 0 .28l-3.063 3.063c-.46.395-.949.477-1.53.282-2.266-.891-6.669-2.825-10.595-6.75-3.957-3.958-6.023-8.446-6.78-10.625-.153-.407-.044-1.008.312-1.313l.062-.063 3.032-3.093A.35.35 0 018.655 5z'
    })
  );
}
export default SvgPhoneSolid;
