import { useEffect } from 'react';

import { ButtonNew } from '@components/button-new';
import { ButtonRefresh } from '@components/button-refresh';
import { Pagination } from '@components/pagination';
import { Table } from '@components/table';

import { OutlinedBox } from '@pages/@common/outlined-box';
import { TopActions } from '@pages/@common/top-actions';
import { useAddUpdateDesingModal } from '@pages/@modals/useAddUpdateDesingModal';
import { StepHeader } from '@pages/admin-page/components/StepHeader';

import { RowActions } from './RowActions';

import { useGetSolarDesigns } from '@api/design/useGetSolarDesigns';

export const StepDesigns = () => {
  const { getSolarDesigns } = useGetSolarDesigns();

  const { addUpdateDesingModal } = useAddUpdateDesingModal();

  const handleRefresh = () => {
    getSolarDesigns.fetch();
  };

  useEffect(() => {
    handleRefresh();
  }, []);

  return (
    <OutlinedBox>
      <StepHeader
        title="Solar Design Types"
        subtTitle="Please select a Design by tapping on the check and Next button"
      />

      <TopActions>
        <ButtonNew
          label="Add Design"
          className="ml-auto"
          onClick={() => {
            addUpdateDesingModal.open({ onAfterSuccess: handleRefresh });
          }}
        />
        <ButtonRefresh onClick={handleRefresh} />
      </TopActions>
      <Table
        data={getSolarDesigns.data?.result.data}
        isBusy={getSolarDesigns.status.isBusy}
        heads={['Design Type', 'Components Description', 'Number of Implementations', 'Actions']}
        emptyMessage="No designs available"
        getRowProps={(rowData, index) => {
          const { designType, componentsDescription, numberOfImplementations } = rowData;

          return {
            nodes: [
              designType,
              componentsDescription,
              numberOfImplementations,
              <RowActions key={index} rowData={rowData} onAfterSuccess={handleRefresh} />
            ]
          };
        }}
        className="h-96"
      />

      <Pagination
        paginatedData={getSolarDesigns.data?.result}
        onChangePage={(page) => getSolarDesigns.fetch({ page })}
        className="mt-4 ml-auto"
      />
    </OutlinedBox>
  );
};
