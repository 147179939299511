import { TabItem, Tabs } from '@components/tabs';

import { useRouter } from '@features/router/useRouter';

import { StepComponents } from './steps/components';
import { StepDesigns } from './steps/designs';
import { StepVendos } from './steps/vendors';

import { isString } from 'formik';

type AdminTab = 'designs' | 'components' | 'vendors';

export const Admin = () => {
  const { query, onChangeQuery } = useRouter();

  const tabsItems: Array<TabItem & { q: AdminTab }> = [
    {
      q: 'designs',
      label: 'Designs',
      content: <StepDesigns />
    },
    {
      q: 'components',
      label: 'Components',
      content: <StepComponents />
    },
    {
      q: 'vendors',
      label: 'Vendors',
      content: <StepVendos />
    }
  ];

  const tabIndexToQuery = (tabIndex: number) => {
    return tabsItems[tabIndex]?.q;
  };
  const tabQueryToIndex = (tabQuery: string) => {
    return tabsItems.findIndex(({ q }) => tabQuery === q);
  };

  return (
    <div className="flex justify-center">
      <Tabs
        tabListClassName="flex justify-center text-black"
        tabPanelClassName="w-[90vw] m-5"
        className="mt-10"
        onSelect={(tabIndex) =>
          onChangeQuery(
            { adminTab: tabIndexToQuery(tabIndex) },
            {
              replaceAll: true
            }
          )
        }
        selected={isString(query.adminTab) ? tabQueryToIndex(query.adminTab) : undefined}
        items={tabsItems}
      />
    </div>
  );
};
