import { useEffect, useRef, useState } from 'react';
import Select from 'react-select';

import { FormFieldWrapper, FormFieldWrapperProps } from '@components/form-field-wrapper';
import { useFormField } from '@components/formux/useFormField';

import { useDebouncer } from '@hooks/useDebouncer';

import { FetchOptions, FetchResourceWithPagination, PaginationQuery } from '@app-types/api';
import { AnyRecord, StyleProps } from '@app-types/general';
import { getFlattenArray, isArray, isEqual } from '@utils/general';

export interface FieldSelectAsyncProps<
  Option extends AnyRecord = AnyRecord,
  Value = any,
  FetchArgs extends (AnyRecord & PaginationQuery) | void = (AnyRecord & PaginationQuery) | void
> extends StyleProps,
    FormFieldWrapperProps {
  useCall: () => FetchResourceWithPagination<FetchArgs, Option>;
  searchToArgs: (search: string) => FetchArgs;
  renderOption: (option: Option) => React.ReactNode;
  optionToValue?: (option: Option) => Value;
  name: string;
  multi?: boolean;
  disabled?: boolean;
  onSelect?: (option: Option) => void;
}

export const FieldSelectAsync = <
  Option extends AnyRecord = AnyRecord,
  Value = any,
  FetchArgs extends AnyRecord | void = AnyRecord | void
>(
  props: Omit<FieldSelectAsyncProps<Option, Value, FetchArgs>, 'items'>
) => {
  const {
    useCall,
    searchToArgs,
    renderOption,
    disabled,
    multi,
    optionToValue,
    onSelect,
    ...omittedProps
  } = props;

  const { field, error } = useFormField(props);
  const [state, setState] = useState<any>();
  const [open, setOpen] = useState(false);
  const [initialFetching, setInitialFetching] = useState(false);
  const [search, setSearch] = useState<string>('');

  const refClickedToOpen = useRef<boolean>(false);

  const { data, fetch, status } = useCall();
  const { value } = field;

  const debouncer = useDebouncer();

  const items = data?.result.data || [];

  const itemToSelectOption = (
    item: Option | undefined
  ): {
    value: any;
    label: React.ReactNode;
  } => {
    if (item === undefined) {
      return {
        value: undefined,
        label: 'Elemento desconocido'
      };
    }

    return {
      value: item,
      label: renderOption(item)
    };
  };

  useEffect(() => {
    let newState = value;

    if (isArray(value)) {
      const foundItems = value.map((v) =>
        items.find((item) => isEqual(optionToValue ? optionToValue(item) : item, v))
      );
      newState = foundItems.map(itemToSelectOption);
    } else {
      const foundItem = items.find((item) => {
        return isEqual(optionToValue ? optionToValue(item) : item, value);
      });

      newState = foundItem ? itemToSelectOption(foundItem) : null;
    }

    setState(newState);
  }, [JSON.stringify([value, items])]);

  const handleChange = (newValue: any) => {
    if (isArray(newValue)) {
      const newSelectedOption = getFlattenArray(newValue);
      setState(newSelectedOption);

      field.onChange({
        target: {
          name: field.name,
          value: newValue.map((v) => (optionToValue ? optionToValue(v.value) : v.value))
        }
      });
    } else {
      const newSelectedOption = newValue;
      setState(newSelectedOption);

      field.onChange({
        target: {
          name: field.name,
          value: optionToValue ? optionToValue(newValue.value) : newValue.value
        }
      });
    }
  };

  const handleFetch = (newSearch: string, options?: FetchOptions) => {
    fetch({ ...searchToArgs(newSearch), pageSize: undefined }, options); //TODO remove the pageSize: undefined and paginate correctli
  };

  const hasValue = isArray(value) ? value.length : value;

  const handleInitialFetch = () => {
    setInitialFetching(true);
    setSearch('');
    handleFetch('', {
      onAfterSuccess: () => {
        setTimeout(() => setInitialFetching(false), 100);
      }
    });
  };

  useEffect(() => {
    if (hasValue && data === null) {
      return handleInitialFetch();
    }

    if (open && data === null) {
      return handleInitialFetch();
    }
  }, [open, hasValue]);

  useEffect(() => {
    if (status.isSuccess && !open && refClickedToOpen.current) {
      setOpen(true);
    }
  }, [status.isSuccess]);

  ////////////////////////////////////////////////////////////////////////////////
  const getPlaceholder = () => {
    if (initialFetching) {
      return 'Loading...';
    }
    return 'Search';
  };

  const getDisabled = () => {
    if (initialFetching || disabled) {
      return true;
    }

    return false;
  };

  return (
    <FormFieldWrapper error={error} {...omittedProps}>
      <Select<{ value: Option }>
        isDisabled={getDisabled()}
        placeholder={getPlaceholder()}
        menuPortalTarget={document.body}
        isLoading={status.isBusy}
        loadingMessage={() => 'Loading...'}
        value={state}
        //
        menuIsOpen={open}
        onMenuOpen={() => {
          refClickedToOpen.current = true;
          setOpen(true);
        }}
        onMenuClose={() => setOpen(false)}
        //
        noOptionsMessage={({ inputValue }) => `There are no results for "${inputValue}"`}
        styles={{
          menuPortal: (base) => ({ ...base, zIndex: 9999 })
          // input: (base) => {
          //   return {
          //     ...base,
          //     '& > input': {
          //       height: '5px',
          //       border: 'none !important'
          //     }
          //   };
          // }
        }}
        options={items.map(itemToSelectOption)}
        onInputChange={(inputValue, { action }) => {
          if (action === 'input-change') {
            setSearch(inputValue);
            debouncer(() => handleFetch(inputValue), 500);
          }
        }}
        inputValue={search || undefined}
        onChange={(newValue, { option }) => {
          if (!newValue) return;

          ////////////////////////////////////////////////
          const selectedValue = isArray(newValue) ? option?.value : newValue.value;
          selectedValue && onSelect?.(selectedValue);
          ////////////////////////////////////////////////

          field.onClick();

          handleChange(newValue);
        }}
        //@ts-expect-error ignore
        isMulti={multi}
        hideSelectedOptions={false}
      />
    </FormFieldWrapper>
  );
};
