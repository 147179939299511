import { FieldCheckbox } from '@components/field-checkbox';
import { FieldInput } from '@components/field-input';
import { FieldRadioGroup } from '@components/field-radio-group';
import { FieldSelectAsync } from '@components/field-select-async';

import { SectionContainer } from './SectionContainer';

import { useGetComponentsByType } from '@api/component/useGetComponentsByType';
import { StyleProps } from '@app-types/general';
import { PanelOrientation } from '@app-types/map';
import { getRequiredLabel } from '@utils/view';

export interface PanelsFeaturesProps extends StyleProps {}

export const PanelsFeatures = ({ className }: PanelsFeaturesProps) => {
  const useCall = () => useGetComponentsByType().getComponentsByType;

  return (
    <SectionContainer label="Panels features" className={className}>
      <form className="flex flex-col gap-4">
        <FieldSelectAsync
          name="panelFeatureState.panelsModelId"
          useCall={useCall}
          label={getRequiredLabel('Model')}
          searchToArgs={() => ({})}
          renderOption={({ description }) => description}
          optionToValue={({ id }) => id}
        />

        <FieldInput
          type="number"
          label={getRequiredLabel('Panels amount')}
          name="panelFeatureState.panelsAmount"
        />

        <FieldInput
          type="number"
          label={getRequiredLabel('Panels separation')}
          name="panelFeatureState.panelsSeparation"
        />

        <FieldRadioGroup<{ value: PanelOrientation; label: string }>
          renderOption={({ checked, item }) => {
            return <FieldCheckbox noUseFormik value={checked} label={item.label} />;
          }}
          optionToValue={({ value }) => value}
          items={[
            {
              value: PanelOrientation.PORTRAIT,
              label: 'Portrait'
            },
            {
              value: PanelOrientation.LANDSCAPE,
              label: 'Landscape'
            }
          ]}
          containerClassName="flex justify-around gap-4"
          label="Orientation"
          name="panelFeatureState.panelsOrientation"
        />
      </form>
    </SectionContainer>
  );
};
