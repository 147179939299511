import { Button } from '@components/button';
import { FieldInput } from '@components/field-input';
import { Formux } from '@components/formux';

import { useRouter } from '@features/router/useRouter';
import { showSuccessToast } from '@features/toast';

import { AuthContainer } from '@pages/@common/auth-container';
import { AuthIconUser } from '@pages/@common/auth-icon-user';
import { OutlinedBox } from '@pages/@common/outlined-box';

import { useAuthForgotPassword } from '@api/auth/useAuthForgotPassword';

export const ForgetPassword = () => {
  const { pushRoute } = useRouter();

  const { authForgotPassword } = useAuthForgotPassword();

  return (
    <AuthContainer
      formContent={
        <>
          <OutlinedBox>
            <div className="flex flex-col items-center gap-2">
              <AuthIconUser />
              <p className="text-2xl text-black font-normal">Forgot Password</p>
            </div>

            <Formux
              value={{ email: '' }}
              validate={[
                {
                  field: 'email',
                  type: 'required',
                  message: 'Email is required'
                },
                {
                  field: 'email',
                  type: 'email',
                  message: 'Email must be valid'
                }
              ]}
            >
              {({ value }) => {
                return (
                  <form className="flex flex-col gap-4">
                    <FieldInput
                      label="E-mail"
                      type="email"
                      name="email"
                      placeholder="Enter your email address"
                    />

                    <Button
                      variant="primary"
                      className="w-100 text-center"
                      formuxSubmit
                      preventDefault
                      isBusy={authForgotPassword.status.isBusy}
                      label="Send Link"
                      onClick={() => {
                        const { email } = value;

                        authForgotPassword.fetch(
                          {
                            email
                          },
                          {
                            onAfterSuccess: ({ message }) => {
                              showSuccessToast(message);
                              pushRoute('/');
                            }
                          }
                        );
                      }}
                    />
                  </form>
                );
              }}
            </Formux>
          </OutlinedBox>
        </>
      }
    />
  );
};
