import { googleMapsApiKey } from '@components/map/utils';

import { useFetch } from '@hooks/useFetch';

import { FetchResource } from '@app-types/api';
import { GetDataLayerResponse } from '@app-types/map';
import { getEndpointSolar } from '@utils/api';

export const useGetDataLayer = (): {
  getDataLayer: FetchResource<{ lat: number; lng: number; radius: number }, GetDataLayerResponse>;
} => {
  const fetch = useFetch<GetDataLayerResponse>();

  return {
    getDataLayer: {
      data: fetch[0],
      status: fetch[1],
      fetch: ({ lat, lng, radius }, options = {}) => {
        fetch[2](
          {
            method: 'get',
            headers: {
              // remove auth Bearer
              Authorization: undefined
            },
            url: getEndpointSolar({
              path: '/dataLayers:get',
              query: {
                key: googleMapsApiKey,
                'location.latitude': lat.toFixed(5),
                'location.longitude': lng.toFixed(5),
                radius_meters: radius?.toString(),
                // The Solar API always returns the highest quality imagery available.
                // By default the API asks for HIGH quality, which means that HIGH quality isn't available,
                // but there is an existing MEDIUM or LOW quality, it won't return anything.
                // Here we ask for *at least* LOW quality, but if there's a higher quality available,
                // the Solar API will return us the highest quality available.
                required_quality: 'LOW'
              }
            })
          },
          options
        );
      },
      reset: fetch[3]
    }
  };
};
