import { useRouter } from '@features/router/useRouter';

import { useAuth } from '@hooks/useAuth';
import { useBreakpoints } from '@hooks/useBreakpoints';

import SvgMapMarkerSolid from '@icons/MapMarkerSolid';
import SvgPhoneSolid from '@icons/PhoneSolid';

export const NavBarTop = () => {
  const { pushRoute } = useRouter();
  const { authData, onSignOut } = useAuth();
  const firstName = authData?.firstName;
  const breakpoints = useBreakpoints();

  if (breakpoints.xs) {
    return (
      <div className="bg-stone-700 flex items-center text-gray-200 px-5 py-4 gap-4">
        {firstName && <span>{`Hello, ${firstName}`}</span>}

        <div
          className="cursor-pointer hover:text-white ml-auto"
          onClick={authData ? onSignOut : () => pushRoute('/login')}
        >
          {authData ? 'Logout' : 'Login'}
        </div>
      </div>
    );
  }

  return (
    <div className="bg-stone-700 flex items-center justify-between text-gray-200 px-20 py-4">
      <div className="flex items-center gap-4">
        <SvgMapMarkerSolid className="size-6 fill-white" /> State: Texas
      </div>
      <div className="flex items-center gap-4">
        {firstName && <span>{`Hello, ${firstName}`},</span>}

        <div className="flex items-center gap-4">
          <SvgPhoneSolid className="size-6 fill-white" /> (210) 555-0132
        </div>

        <div
          className="cursor-pointer hover:text-white"
          onClick={authData ? onSignOut : () => pushRoute('/login')}
        >
          {authData ? 'Logout' : 'Login'}
        </div>
      </div>
    </div>
  );
};
