import { useFetch } from '@hooks/useFetch';

import { FetchResource } from '@app-types/api';
import { Coordinates } from '@app-types/map';
import { getEndpoint } from '@utils/api';
import { makeReshaper } from '@utils/makeReshaper';

interface ServiceCoordinates {
  lat: number;
  lon: number;
}

const reshaperFromCoordinates = makeReshaper<Coordinates, ServiceCoordinates>({
  lat: 'lat',
  lon: 'lng'
});

const reshaperToCoordinates = makeReshaper<ServiceCoordinates, Coordinates>({
  lat: 'lat',
  lng: 'lon'
});

interface Args {
  orientationSegment: Array<Coordinates>;
  roofArea: Array<Coordinates>;
  pvId: number;
  panelsAmount: number;
  panelsOrientation: number;
  panelsSeparation: number;
  location: Coordinates;
  useGoogleSolar?: boolean;
}

export const useComputeTilesPosition = (): {
  computeTilesPosition: FetchResource<Args, Array<Array<Coordinates>>>;
} => {
  const fetch = useFetch<Array<Array<Coordinates>>>();

  return {
    computeTilesPosition: {
      data: fetch[0],
      status: fetch[1],
      fetch: (data, options = {}) => {
        fetch[2](
          {
            method: 'post',
            url: getEndpoint({
              path: '/tiles/compute-tiles-position'
            }),
            data: {
              ...data,
              orientationSegment: data.orientationSegment.map(reshaperFromCoordinates),
              roofArea: data.roofArea.map(reshaperFromCoordinates),
              location: reshaperFromCoordinates(data.location)
            }
          },
          {
            ...options,
            onResponseProcessing: ({ message, result }) => {
              return {
                message,
                //@ts-expect-error ignore
                result: result.map((d) => d.map(reshaperToCoordinates)) as Array<Array<Coordinates>>
              };
            }
          }
        );
      },
      reset: fetch[3]
    }
  };
};
