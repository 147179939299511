import { googleMapsApiKey } from '@components/map/utils';

import { useFetch } from '@hooks/useFetch';

import { FetchResource } from '@app-types/api';
import { GeoTiff } from '@app-types/map';
import { fromArrayBuffer, TypedArray } from 'geotiff';
import * as geokeysToProj4 from 'geotiff-geokeys-to-proj4';
import proj4 from 'proj4';

/**
 * https://developers.google.com/maps/documentation/solar/data-layers?hl=es-419
 */

export const onResultProcessing = async (result: any): Promise<GeoTiff> => {
  // Get the GeoTIFF rasters, which are the pixel values for each band.

  const arrayBuffer = await result.arrayBuffer();
  const tiff = await fromArrayBuffer(arrayBuffer);

  const image = await tiff.getImage();
  const rasters = await image.readRasters();

  // Reproject the bounding box into lat/lon coordinates.
  const geoKeys = image.getGeoKeys();
  const projObj = geokeysToProj4.toProj4(geoKeys);
  const projection = proj4(projObj.proj4, 'WGS84');
  const box = image.getBoundingBox();
  const sw = projection.forward({
    x: box[0] * projObj.coordinatesConversionParameters.x,
    y: box[1] * projObj.coordinatesConversionParameters.y
  });
  const ne = projection.forward({
    x: box[2] * projObj.coordinatesConversionParameters.x,
    y: box[3] * projObj.coordinatesConversionParameters.y
  });

  return {
    // Width and height of the data layer image in pixels.
    // Used to know the row and column since Javascript
    // stores the values as flat arrays.
    width: rasters.width,
    height: rasters.height,
    // Each raster reprents the pixel values of each band.
    // We convert them from `geotiff.TypedArray`s into plain
    // Javascript arrays to make them easier to process.
    rasters: [...Array(rasters.length).keys()].map((i) => Array.from(rasters[i] as TypedArray)),
    // The bounding box as a lat/lon rectangle.

    bounds: {
      north: ne.y,
      south: sw.y,
      east: ne.x,
      west: sw.x
    }
  };
};

export const useDownloadGeoTiff = (): {
  downloadGeoTiff: FetchResource<{ url: string }, GeoTiff>;
} => {
  const fetch = useFetch<GeoTiff>();

  return {
    downloadGeoTiff: {
      data: fetch[0],
      status: fetch[1],
      fetch: ({ url }, options = {}) => {
        fetch[2](
          {
            method: 'get',
            headers: {
              // remove auth Bearer
              Authorization: undefined
            },
            url: `${url}&key=${googleMapsApiKey}`
          },
          {
            ...options,
            useNativeFetch: true,
            onResponseProcessing: async ({ message, result }) => {
              return {
                message,
                result: await onResultProcessing(result)
              };
            }
          }
        );
      },
      reset: fetch[3]
    }
  };
};
