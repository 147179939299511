import { useEffect, useState } from 'react';

import { Button } from '@components/button';
import { ButtonSave } from '@components/button-save';
import { FieldCheckbox } from '@components/field-checkbox';
import { FieldInput } from '@components/field-input';
import { FieldSelect } from '@components/field-select';
import { Formux } from '@components/formux';
import { Loader } from '@components/loader';

import { useRouter } from '@features/router/useRouter';

import { OutlinedBox } from '@pages/@common/outlined-box';

import { useGetGeolocationAutocomplete } from '@api/geolocation/useGetGeolocationAutocomplete';
import { useGetGeolocationDetails } from '@api/geolocation/useGetGeolocationDetails';
import { useGetGeolocationGeocode } from '@api/geolocation/useGetGeolocationGeocode';
import { useAddReport } from '@api/reports/useAddReport';
import { useGetReports } from '@api/reports/useGetReports';
import { ReportState } from '@app-types/reports';
import Swal from 'sweetalert2';

interface StateData {
  sliderValue: number;
  getReportSlider: number;
  getReportParameter: number;
  selectedCoolingType: string;
  selectedHeatingType: string;
  selectedEnergySupplierType: string;
  predictions: Array<{
    address: string;
    placeId: string;
  }>;
  mapCenter: { lat: number; lng: number };
  address: string;
  city: string;
  state: string;
  zip: string;
}

export const RightSide = () => {
  const [stateData, setStateData] = useState<StateData>({
    sliderValue: 80,
    getReportSlider: 0,
    getReportParameter: 0,
    selectedCoolingType: '',
    selectedHeatingType: '',
    selectedEnergySupplierType: '',
    predictions: [],
    mapCenter: { lat: 0, lng: 0 },
    address: '',
    city: '',
    state: '',
    zip: ''
  });

  // const { authData } = useAuth();
  const { pushRoute } = useRouter();
  /**
   * Item values
   */
  // const { getItemValues: getCoolingData } = useGetItemValues();
  // const { getItemValues: getHeatingData } = useGetItemValues();
  // const { getItemValues: getEnergyProviderData } = useGetItemValues();

  // const coolingData = getCoolingData.data?.result;
  // const heatingData = getHeatingData.data?.result;
  // const energyProviderData = getCoolingData.data?.result;

  /**
   * Geolocation service
   */
  const { getGeolocationAutocomplete } = useGetGeolocationAutocomplete();
  const { getGeolocationGeocode } = useGetGeolocationGeocode();
  const { getGeolocationGeocode: getGeolocationGeocodeZip } = useGetGeolocationGeocode();
  const { getGeolocationDetails } = useGetGeolocationDetails();

  const geolocation = getGeolocationGeocode.data?.result?.[0];
  const zipcodelocation = getGeolocationGeocodeZip.data?.result?.[0];
  const placeIdDetails = getGeolocationDetails.data?.result;
  const searchAddresData = getGeolocationAutocomplete.data?.result;

  /**
   * Reports
   */

  const { getReports } = useGetReports();
  const { addReport } = useAddReport();

  // const ReportData = getReports.data?.result?.[0] || {};
  // const reportData = ReportData.reportData;
  // const reportParameters = ReportData.reportParameters;
  const isLoading = getReports.status.isBusy;

  const [formState, setFormState] = useState<ReportState>({
    systemTypeId: 1,
    buildingTypeId: '1',
    roles: []
  });

  useEffect(() => {
    const { city, state, postalCode, address } = zipcodelocation || {};
    if (zipcodelocation && city && state && postalCode && address) {
      setStateData((prevState) => ({
        ...prevState,
        address: address,
        state: state,
        city: city,
        zip: postalCode
      }));

      setFormState((formState) => ({ ...formState, address, city, state, zip: postalCode }));
    }
  }, [zipcodelocation]);

  useEffect(() => {
    if (Array.isArray(searchAddresData)) {
      const updatedPredictions = searchAddresData.map((item) => ({
        address: item.address,
        placeId: item.placeId
      }));
      if (updatedPredictions && updatedPredictions.length > 0) {
        setStateData((prevState) => ({
          ...prevState,
          predictions: updatedPredictions
        }));
      }
    }
  }, [searchAddresData]);

  useEffect(() => {
    if (geolocation) {
      const { address, city, state, postalCode } = geolocation;

      setStateData((prevState) => ({
        ...prevState,
        address: address,
        state: state,
        city: city,
        zip: postalCode
      }));

      setFormState((formState) => ({ ...formState, address, city, state, zip: postalCode }));
    }
  }, [geolocation]);

  useEffect(() => {
    if (placeIdDetails) {
      const { address, city, state, postalCode } = placeIdDetails;
      if (address) {
        setStateData((prevState) => ({
          ...prevState,
          address: address
        }));
        setFormState((formState) => ({ ...formState, address }));
      }
      if (city) {
        setStateData((prevState) => ({
          ...prevState,
          city: city
        }));
        setFormState((formState) => ({ ...formState, city }));
      }
      if (state) {
        setStateData((prevState) => ({
          ...prevState,
          state: state
        }));
        setFormState((formState) => ({ ...formState, state }));
      }
      if (postalCode) {
        setStateData((prevState) => ({
          ...prevState,
          zip: postalCode
        }));
        setFormState((formState) => ({ ...formState, zip: postalCode }));
      }
    }
  }, [placeIdDetails]);

  const handleAutofill = async () => {
    try {
      if ('geolocation' in navigator) {
        navigator.geolocation.getCurrentPosition(
          async (position) => {
            const lat = position.coords.latitude;
            const lon = position.coords.longitude;

            getGeolocationGeocode.fetch({ lat, lon });
          },
          (error) => {
            console.error('Error getting location:', error.message);
          }
        );
      } else {
        console.error('Geolocation is not supported by your browser');
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };

  if (isLoading) {
    return (
      <div>
        <Loader />
      </div>
    );
  }

  const renderMixedLabel = (label: string, description: string) => {
    return (
      <div>
        {label} <span className="text-xs">({description})</span>
      </div>
    );
  };

  return (
    <OutlinedBox className="w-2/3">
      <Formux<ReportState>
        value={formState}
        validate={[
          {
            field: 'address',
            type: 'required',
            message: 'Address name is required'
          },
          {
            field: 'city',
            type: 'required',
            message: 'City is required'
          },
          {
            field: 'state',
            type: 'required',
            message: 'State is required'
          },
          {
            field: 'zip',
            type: 'required',
            message: 'Zip is required'
          },
          {
            field: 'coolingTypeId',
            type: 'required',
            message: 'Cooling is required'
          },
          {
            field: 'heatingTypeId',
            type: 'required',
            message: 'Heating is required'
          },
          {
            field: 'roofAge',
            type: 'required',
            message: 'Roof is required'
          },
          {
            field: 'suiteNum',
            type: 'required',
            message: 'Suit Num is required'
          },
          {
            field: 'avgkW',
            type: 'required',
            message: 'AvgkW is required'
          },
          {
            field: 'utilityCharge',
            type: 'required',
            message: 'Utility Charge is required'
          },
          {
            field: 'estimatedkWPerHour',
            type: 'required',
            message: 'Estimated kWPerHour is required'
          },
          {
            field: 'yearlyElectricityCost',
            type: 'required',
            message: 'Yearly Electricity Cost is required'
          },
          {
            field: 'energySupplierId',
            type: 'required',
            message: 'Energy Supplier is required'
          }
        ]}
      >
        {({ value, resetForm }) => {
          return (
            <form className="w-full flex flex-col gap-8">
              <div className="flex gap-6">
                <OutlinedBox title="Type of solar" titleBgVariant="light">
                  <div className="flex gap-4">
                    <FieldCheckbox label="Grid-tied" name="field0" />
                    <FieldCheckbox label="Off-tied" name="field0" />
                  </div>
                </OutlinedBox>

                <OutlinedBox title="Work I will Perform" titleBgVariant="light">
                  <div className="flex gap-4">
                    <FieldCheckbox label="Roofer" name="field0" />
                    <FieldCheckbox label="Electrician" name="field0" />
                    <FieldCheckbox label="PE-Load-Bearing Analysis" name="field0" />
                  </div>
                </OutlinedBox>
              </div>

              <div className="bg-sky-800 flex items-center justify-center gap-6 p-3 text-gray-300">
                Save time. Autofill your current location.
                <Button preventDefault label="Autofill" onClick={handleAutofill} />
              </div>

              <FieldInput label="Address" placeholder="Enter address" name="field0" />

              <div className="flex gap-4 justify-center">
                <FieldCheckbox label="Single family" name="field0" />
                <FieldCheckbox label="Townhome" name="field0" />
                <FieldCheckbox label="Commercial" name="field0" />
              </div>

              <div className="grid gap-6 grid-cols-3">
                <FieldInput label="City" placeholder="City" name="field0" />
                <FieldInput label="State" placeholder="State" name="field0" />
                <FieldInput label="Zip" placeholder="Zip" name="field0" />

                {/* ////////////// */}
                <FieldSelect
                  label="Cooling"
                  items={[
                    {
                      value: 'No colling'
                    },
                    {
                      value: 'Central AC'
                    },
                    {
                      value: 'Window AC'
                    }
                  ]}
                  optionToValue={({ value }) => value}
                  renderOption={({ value }) => value}
                  renderValue={({ value }) => value}
                  name="field0"
                />
                <FieldSelect
                  label="Heating"
                  items={[
                    {
                      value: 'No heating'
                    },
                    {
                      value: 'Central heating system'
                    },
                    {
                      value: 'Window heating'
                    }
                  ]}
                  optionToValue={({ value }) => value}
                  renderOption={({ value }) => value}
                  renderValue={({ value }) => value}
                  name="field0"
                />
                <FieldInput
                  type="number"
                  label={renderMixedLabel('Age of roof', 'in years')}
                  name="field0"
                />
                {/* ////////////// */}

                <FieldInput
                  type="number"
                  label={renderMixedLabel('Utility Charge', 'cents per kWH')}
                  name="field0"
                />
                <div className="flex flex-col gap-4">
                  <FieldInput type="number" label="Est. kWhrs Consumed" name="field0" />
                  <Button label="Restore Original Value" />
                </div>
                <FieldInput type="number" label="Yearly Electricity Cost" name="field0" />

                <FieldSelect
                  label="Heating"
                  items={[
                    {
                      value: 'No heating'
                    },
                    {
                      value: 'Central heating system'
                    },
                    {
                      value: 'Window heating'
                    }
                  ]}
                  optionToValue={({ value }) => value}
                  renderOption={({ value }) => value}
                  renderValue={({ value }) => value}
                  name="field0"
                />

                <FieldSelect
                  className="col-span-2"
                  label="Net Metering Policy"
                  items={[
                    {
                      value: 'Excess power purchased at full retail price'
                    }
                  ]}
                  optionToValue={({ value }) => value}
                  renderOption={({ value }) => value}
                  renderValue={({ value }) => value}
                  name="field0"
                />
              </div>

              <ButtonSave
                label="Save Changes"
                className="w-fit ml-auto"
                preventDefault
                formuxSubmit
                onClick={async () => {
                  const {
                    address,
                    city,
                    state,
                    zip,
                    roles,
                    coolingTypeId,
                    heatingTypeId,
                    roofAge,
                    // suiteNum,
                    month,
                    avgkW
                    // utilityCharge,
                    // estimatedkWPerHour,
                    // yearlyElectricityCost,
                    // energySupplierId
                  } = value;

                  if (!month || !avgkW) {
                    return;
                  }

                  const confirmationResult = await Swal.fire({
                    title: 'Save Changes?',
                    text: 'Do you want to save changes?',
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Save',
                    cancelButtonText: 'Not Now'
                  });

                  if (confirmationResult.isConfirmed) {
                    try {
                      addReport.fetch(
                        {
                          address,
                          city,
                          state,
                          zip,
                          coolingTypeId,
                          heatingTypeId,
                          roofAge,
                          // suiteNum,
                          month,
                          // utilityCharge,
                          // estimatedkWPerHour,
                          // yearlyElectricityCost,
                          // energySupplierId,
                          percentOfEnergyToGenerate: stateData.sliderValue,
                          consumption: [
                            {
                              month,
                              avgkW
                            }
                          ],
                          roles
                        },
                        {
                          onAfterSuccess: (response) => {
                            localStorage.setItem('savedResponse', JSON.stringify(response.result));
                            if (response.result === true) {
                              Swal.fire({
                                title: 'Great Job!',
                                icon: 'success',
                                text: response.message
                              }).then((result) => {
                                if (result.isConfirmed) {
                                  resetForm();
                                }
                              });
                            } else {
                              localStorage.setItem('response', 'true');
                              pushRoute('/login');
                            }
                          }
                        }
                      );
                    } catch (error) {
                      console.error('Error:', error);
                    }
                  }
                }}
              />
            </form>
          );
        }}
      </Formux>
    </OutlinedBox>
  );
};
