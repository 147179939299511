import 'react-toastify/dist/ReactToastify.css';
import {
  toast,
  ToastContainer as ToastContainerBase,
  ToastContent,
  ToastOptions
} from 'react-toastify';

const CONFIG_OPTIONS: ToastOptions = {
  position: 'bottom-center',
  autoClose: 3000,
  hideProgressBar: true,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined
};

//eslint-disable-next-line
export const showInfoToast = (toastMessage: ToastContent) => {
  toast.info(toastMessage, CONFIG_OPTIONS);
};

//eslint-disable-next-line
export const showSuccessToast = (toastMessage: ToastContent) => {
  toast.success(toastMessage, CONFIG_OPTIONS);
};

//eslint-disable-next-line
export const showWarnToast = (toastMessage: ToastContent) => {
  toast.warn(toastMessage, CONFIG_OPTIONS);
};

//eslint-disable-next-line
export const showErrorToast = (toastMessage: ToastContent) => {
  toast.error(toastMessage, CONFIG_OPTIONS);
};

export const ToastContainer = () => {
  return <ToastContainerBase />;
};
