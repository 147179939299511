import { SliderMonths } from '@components/slider-months';

import { useIrradianceMap } from '../useIrradianceMap';
import { useMapState } from '../useMapState';

import { StyleProps } from '@app-types/general';
import { cn } from '@utils/general';

export interface MapSliderMonthsProps extends StyleProps {}

export const MapSliderMonths = ({ className }: MapSliderMonthsProps) => {
  const { setValue, value } = useMapState();
  const { irradianceMapData } = value;

  const { onDownloadGeoTiff } = useIrradianceMap();

  return (
    <div className={cn('absolute top-5 left-60 right-20 z-10', className)}>
      <SliderMonths
        value={irradianceMapData.month}
        onChange={(month) => {
          const newState = {
            ...irradianceMapData,
            month
          };

          setValue((state) => ({ ...state, irradianceMapData: newState }));
          onDownloadGeoTiff(newState);
        }}
      />
    </div>
  );
};
