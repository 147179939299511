import { IMAGES } from '@component/common/Constants/Constants';

import Image from '@assets/bg-lines.png';

import { Logo } from '../logo';

import { cn } from '@utils/general';

export interface AuthContainerProps {
  formContent: React.ReactNode;
}
export const AuthContainer = ({ formContent }: AuthContainerProps) => {
  return (
    <div className="flex flex-col lg:flex-row items-center">
      <div className="flex flex-col justify-center w-full">
        <div className="m-16">
          <figure className="flex justify-center w-full">
            <Logo />
          </figure>

          <div className="mt-10">{formContent}</div>
        </div>
      </div>

      <div className="flex flex-col justify-center w-full">
        <div
          style={{ backgroundImage: `url(${Image})` }}
          className={cn(
            'bg-sky-800 p-16 bg-cover flex flex-col items-center gap-4 text-center text-white'
          )}
        >
          <p className="text-4xl">Let’s conserve for the Future</p>
          <p>
            We value natural resources not as an end but for using it to create differences and
            create a better life.
          </p>

          <div className="relative">
            <img src={IMAGES.VideoImg} alt="Video Imag" />
            <a
              href="#"
              className={cn(
                'absolute inset-0 m-auto z-10 size-14 flex items-center p-2 justify-center bg-white rounded-full',
                'shadow-[0_0_20px_5px_rgba(100,200,0,0.3)]'
              )}
            >
              <svg
                x="0px"
                y="0px"
                viewBox="0 0 16 19"
                className="fill-lime-500 size-6 ml-1.5 mt-0.5"
              >
                <path d="M3.8,0.9C2.5,0,0.7,0.9,0.7,2.5v13.9c0,1.6,1.8,2.5,3.1,1.7l10.6-7c1.2-0.8,1.2-2.6,0-3.3L3.8,0.9z" />
              </svg>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};
