import { useFetch } from '@hooks/useFetch';

import { FetchResourceWithPagination, PaginatedData, PaginationQuery } from '@app-types/api';
import { getEndpoint } from '@utils/api';
import { defaultPaginationQuery } from '@utils/pagination';

interface ComponentData {
  description: string;
  id: string;
}

export const useGetComponentsByType = (): {
  getComponentsByType: FetchResourceWithPagination<void | PaginationQuery, ComponentData>;
} => {
  const fetch = useFetch<PaginatedData<ComponentData>>();

  return {
    getComponentsByType: {
      data: fetch[0],
      status: fetch[1],
      fetch: (query = {}, options = {}) => {
        fetch[2](
          {
            method: 'get',
            url: getEndpoint({
              path: '/component/get-components-by-type',
              query: { type: 'PV', ...defaultPaginationQuery, ...query }
            })
          },
          options
        );
      },
      reset: fetch[3]
    }
  };
};
