import safety from '@assets/safetys.png';
import speaker from '@assets/speakers.png';
import thunder from '@assets/thunders.png';
import watch from '@assets/watchs.png';

export const Section7 = () => {
  const rendercard = (imgSrc: string, text: string) => {
    return (
      <div className="flex flex-col gap-6 items-center bg-white text-black p-5 rounded-lg max-w-80 mx-auto">
        <img src={imgSrc} className="h-40" />
        <h5 className="text-center font-bold">{text}</h5>
      </div>
    );
  };
  return (
    <section className="text-white bg-sky-700 p-8">
      <h2 className="text-center text-2xl md:text-4xl lg:text-5xl font-bold mt-6">
        About All-in-one Battery Energy Storage Systems (BESS for short)
      </h2>
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-8 mt-6">
        {rendercard(
          safety,
          'Most grids are pretty reliable, and even when it fails the average down time is only 45 minutes.'
        )}

        {rendercard(
          watch,
          'On rare occasions the outage may last more than 45 minutes, especially in times of extreme heat or cold when the grid is stressed.'
        )}

        {rendercard(
          thunder,
          ' Your home router and security camera will be disabled during the time of the outage.'
        )}

        {rendercard(
          speaker,
          'Generators are cheaper than Lithium batteries, but your neighbors may not appreciate the noise.'
        )}
      </div>
    </section>
  );
};
