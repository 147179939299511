import { useFetch } from '@hooks/useFetch';

import { FetchResourceWithPagination, PaginatedData, PaginationQuery } from '@app-types/api';
import { SolarDesign } from '@app-types/general';
import { getEndpoint } from '@utils/api';
import { defaultPaginationQuery } from '@utils/pagination';

export const useGetSolarDesigns = (): {
  getSolarDesigns: FetchResourceWithPagination<PaginationQuery | void, SolarDesign>;
} => {
  const fetch = useFetch<PaginatedData<SolarDesign>>();

  return {
    getSolarDesigns: {
      data: fetch[0],
      status: fetch[1],
      fetch: (query = {}, options = {}) => {
        fetch[2](
          {
            method: 'get',
            url: getEndpoint({
              path: '/design/get-solar-designs',
              query: { ...defaultPaginationQuery, ...query }
            })
          },
          options
        );
      },
      reset: fetch[3]
    }
  };
};
