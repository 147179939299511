import { Link } from 'react-router-dom';

import { MenuProps } from '@components/menu';

import { useRouter } from '@features/router/useRouter';

import { Nullable, StyleProps } from '@app-types/general';
import { cn, compact } from '@utils/general';

interface Item {
  name: string;
  href?: string;
  className?: string;
  menuProps?: Pick<MenuProps, 'items'>;
  onClick?: () => void;
}

export interface NavBarLayoutProps extends StyleProps {
  preContent?: React.ReactNode;
  items: Array<Nullable<Item>>;
  postContent?: React.ReactNode;
}

export const NavBarLayout = ({ items, preContent, postContent, className }: NavBarLayoutProps) => {
  const { pathname } = useRouter();

  return (
    <div>
      <div
        className={cn(
          'w-full px-2 sm:px-8 bg-stone-900 flex shadow-lg items-center justify-around h-20 gap-1 sm:gap-6',
          className
        )}
      >
        {preContent}

        <div className="hidden lg:flex">
          {compact(items).map(({ href, name, className, onClick }) => {
            const current = pathname === href;

            // if (menuProps) {
            //   return (
            //     <Menu
            //       key={name}
            //       {...menuProps}
            //       buttonElement={
            //         <div
            //           className={cn(
            //             {
            //               'text-gray-600': current,
            //               'text-gray-700 hover:text-gray-600': !current
            //             },
            //             'rounded-md px-3 py-2 text-sm font-medium text-nowrap',
            //             className
            //           )}
            //         >
            //           {name}
            //         </div>
            //       }
            //     />
            //   );
            // }

            const styles = cn(
              'rounded-md px-3 py-2 text-md font-semibold text-nowrap',
              {
                'text-white': current,
                'text-gray-200 hover:text-white': !current
              },
              className
            );

            if (href) {
              return (
                <Link
                  key={name}
                  to={href}
                  className={styles}
                  aria-current={current ? 'page' : undefined}
                >
                  {name}
                </Link>
              );
            }

            if (onClick) {
              return (
                <div key={name} onClick={onClick} className={cn('cursor-pointer', styles)}>
                  {name}
                </div>
              );
            }

            return <>unknown</>;
          })}
        </div>

        {postContent && <div className="flex items-center gap-0 ml-auto">{postContent}</div>}
      </div>
    </div>
  );
};
