import { Section0 } from './Section0';
import { Section2 } from './Section2';
import { Section3 } from './Section3';
import { Section4 } from './Section4';
import { Section5 } from './Section5';
import { Section7 } from './Section7';
import { Section8 } from './Section8';

export const Home = () => {
  return (
    <>
      <Section0 />

      <Section2 />

      <Section7 />

      <Section3 />

      <Section8 />

      <Section4 />

      <Section5 />
    </>
  );
};
