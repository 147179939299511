import { StandaloneSearchBox } from '@react-google-maps/api';
import { useRef } from 'react';

import { ButtonSave } from '@components/button-save';
import { FieldInput } from '@components/field-input';
import { Formux } from '@components/formux';

import { useMapState } from '../useMapState';
import { SectionContainer } from './SectionContainer';

import { StyleProps } from '@app-types/general';
import { isNumber } from '@utils/general';

export interface BuildingAddressProps extends StyleProps {}

export const BuildingAddress = ({ className }: BuildingAddressProps) => {
  const { setValue, value } = useMapState();

  const { isLoaded } = value;

  const refSearchBox = useRef<google.maps.places.SearchBox>();

  return (
    <SectionContainer label="Building address" className={className}>
      <Formux<{ search: string }> value={{ search: '' }}>
        {() => {
          return (
            <form className="flex items-center gap-2">
              {isLoaded && (
                <StandaloneSearchBox
                  onLoad={(ref) => {
                    refSearchBox.current = ref;
                  }}
                >
                  <FieldInput placeholder="Enter an address..." name="search" className="w-52" />
                </StandaloneSearchBox>
              )}

              <ButtonSave
                formuxSubmit
                preventDefault
                label="Go"
                rounded
                className="ml-auto"
                onClick={() => {
                  const newPlaces = refSearchBox.current?.getPlaces();

                  if (newPlaces && newPlaces.length > 0) {
                    const place = newPlaces[0];
                    const lat = place.geometry?.location?.lat();
                    const lng = place.geometry?.location?.lng();

                    if (isNumber(lat) && isNumber(lng)) {
                      setValue((state) => ({ ...state, searchedLocation: { lat, lng } }));
                    }
                  }
                }}
              />
            </form>
          );
        }}
      </Formux>
    </SectionContainer>
  );
};
