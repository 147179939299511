import { Button } from '@components/button';
import { FormFieldWrapper } from '@components/form-field-wrapper';

import { useIrradianceMap } from '../useIrradianceMap';
import { useMapState } from '../useMapState';
import { SectionContainer } from './SectionContainer';

import { useComputeTilesPosition } from '@api/tiles/useComputeTilesPosition';
import { StyleProps } from '@app-types/general';
import { cn, isNullOrUndefined } from '@utils/general';
import { getCenterPoint } from '@utils/map';

export interface ActionsProps extends StyleProps {}

export const Actions = ({ className }: ActionsProps) => {
  const { hasValidSelectedArea, hasValidIrradianceMapData, value, setValue, errors } =
    useMapState();

  const {
    selectingArea,
    //
    selectingAreaMarkers,
    selectedArea,
    //
    selectingRidgeLine,
    ridgeLinePoints,
    panelFeatureState
  } = value;

  const { onComputeIrradianceMap } = useIrradianceMap();

  const { computeTilesPosition } = useComputeTilesPosition();

  const handlePlacePanelButtonClick = () => {
    const { panelsSeparation, panelsAmount, panelsOrientation, panelsModelId } = panelFeatureState;

    if (
      isNullOrUndefined(panelsSeparation) ||
      isNullOrUndefined(panelsAmount) ||
      isNullOrUndefined(panelsOrientation) ||
      isNullOrUndefined(panelsModelId)
    ) {
      return;
    }

    computeTilesPosition.fetch(
      {
        orientationSegment: ridgeLinePoints,
        roofArea: selectedArea,
        pvId: panelsModelId,
        panelsAmount,
        panelsOrientation,
        panelsSeparation,
        location: getCenterPoint(selectedArea)
      },
      {
        onAfterSuccess: ({ result }) => {
          setValue((state) => ({ ...state, panels: result }));
        }
      }
    );
  };

  return (
    <SectionContainer label="Building address" className={cn('flex flex-col gap-4', className)}>
      <FormFieldWrapper error={errors.selectedArea}>
        <Button
          className="w-full"
          label={selectingArea ? 'Finish Area' : 'Select Area'}
          variant={selectingArea ? 'outlined' : 'primary'}
          disabled={selectingRidgeLine}
          onClick={() => {
            if (selectingArea) {
              setValue((state) => ({
                ...state,
                selectingArea: false,
                selectedArea: selectingAreaMarkers.map(({ lat, lng }) => ({ lat, lng })),
                selectingAreaMarkers: []
              }));
            } else {
              setValue((state) => ({
                ...state,
                selectingArea: true,
                selectedArea: [],
                selectingAreaMarkers: [],
                irradianceMapData: {}
              }));
            }
          }}
        />
      </FormFieldWrapper>

      <FormFieldWrapper error={errors.ridgeLinePoints}>
        <Button
          className="w-full"
          label={selectingRidgeLine ? 'Clear direction' : 'Set direction'}
          variant={selectingRidgeLine ? 'outlined' : 'primary'}
          disabled={selectingArea}
          onClick={() => {
            setValue((state) => ({
              ...state,
              selectingRidgeLine: !selectingRidgeLine,
              ridgeLinePoints: []
            }));
          }}
        />
      </FormFieldWrapper>

      <Button
        label={hasValidIrradianceMapData ? 'Hide irradiance map' : 'Show irradiance map'}
        disabled={!hasValidSelectedArea}
        onClick={() => {
          if (hasValidIrradianceMapData) {
            setValue((state) => ({ ...state, irradianceMapData: {} }));
          } else {
            onComputeIrradianceMap();
          }
        }}
      />
      <Button formuxSubmit label="Place panels" onClick={handlePlacePanelButtonClick} />
    </SectionContainer>
  );
};
