import { useState } from 'react';

import { FormFieldWrapper, FormFieldWrapperProps } from '@components/form-field-wrapper';
import { useFormField } from '@components/formux/useFormField';

import { StyleProps } from '@app-types/general';
import { isNullOrUndefined, removeRow } from '@utils/general';

export interface FieldListProps<O = any> extends FormFieldWrapperProps, StyleProps {
  name?: string;
  renderAddButton?: (args: { onAfterSuccess: (item: O) => void }) => React.ReactNode;
  renderRemoveButton?: (args: { disabled: boolean; onRemove: () => void }) => React.ReactNode;
  renderOption: (args: { checked: boolean; item: O; index: number }) => React.ReactNode | null;
}

export const FieldList = <O extends any = any>(props: FieldListProps<O>) => {
  const { className, renderOption, renderAddButton, renderRemoveButton, label, description } =
    props;
  const { field, error } = useFormField(props);

  const [selectedIndex, setSelectedIndex] = useState<number>();

  const value: Array<O> = field.value || [];

  return (
    <FormFieldWrapper error={error} label={label} description={description} className={className}>
      <div className="flex flex-col gap-4">
        <div className="flex flex-col ring-1 ring-sky-800 h-40 max-h-40 overflow-y-auto">
          {value.map((item, index) => (
            <div
              key={index}
              onClick={() => {
                setSelectedIndex(selectedIndex === index ? undefined : index);
              }}
            >
              {renderOption({ item, index: 0, checked: index === selectedIndex })}
            </div>
          ))}
        </div>

        <div className="flex gap-4">
          {renderAddButton?.({
            onAfterSuccess: (vendor) => {
              field.onClick();

              field.onChange({
                target: {
                  name: field.name,
                  value: [...value, vendor]
                }
              });
            }
          })}
          {renderRemoveButton?.({
            disabled: isNullOrUndefined(selectedIndex),
            onRemove: () => {
              if (isNullOrUndefined(selectedIndex)) return;

              field.onClick();

              field.onChange({
                target: {
                  name: field.name,
                  value: removeRow(value, selectedIndex)
                }
              });

              setSelectedIndex(undefined);
            }
          })}
        </div>
      </div>
    </FormFieldWrapper>
  );
};
