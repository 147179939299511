import { useEffect } from 'react';

import { Button } from '@components/button';
import { FieldInput } from '@components/field-input';
import { FieldList } from '@components/field-list';
import { Formux } from '@components/formux';

import { OutlinedBox } from '@pages/@common/outlined-box';
import { StepHeader } from '@pages/admin-page/components/StepHeader';

import { useAddVendors } from '@api/vendor/useAddVendors';
import { useGetVendors } from '@api/vendor/useGetVendors';
import { Vendor } from '@app-types/general';
import { cn } from '@utils/general';

export const StepVendos = () => {
  const { getVendors } = useGetVendors();
  const { addVendors } = useAddVendors();

  const vendors = getVendors.data?.result.data || [];

  useEffect(() => {
    getVendors.fetch();
  }, []);

  return (
    <OutlinedBox>
      <StepHeader title="Vendors" subtTitle="Please provide the information of the new vendor" />

      <div className="flex items-center gap-6">
        <Formux<{
          name?: string;
          website?: string;
          vendors?: Array<Vendor>;
        }>
          value={{ name: '', website: '', vendors: vendors || [] }}
          validate={[
            {
              field: 'name',
              type: 'required'
            },
            {
              field: 'website',
              type: 'required'
            }
          ]}
        >
          {({ resetForm, value }) => {
            return (
              <form className="w-full flex flex-col lg:flex-row gap-6">
                <div className="flex flex-col gap-4 w-full">
                  <FieldInput label="Vendor Name" placeholder="Enter vendor name" name="name" />
                  <FieldInput label="Website" name="website" />

                  <Button
                    className="w-fit ml-auto"
                    formuxSubmit
                    preventDefault
                    label="Add"
                    onClick={() => {
                      const { name, website } = value;

                      if (!name || !website) return;

                      addVendors.fetch(
                        {
                          name,
                          website
                        },
                        {
                          onAfterSuccess: () => {
                            resetForm();
                            getVendors.fetch();
                          }
                        }
                      );
                    }}
                  />
                </div>

                <FieldList<Vendor>
                  className="w-full"
                  label="Current Vendors"
                  name="vendors"
                  renderOption={({ checked, item }) => {
                    return (
                      <div
                        className={cn('cursor-pointer text-sm p-2', {
                          'bg-sky-800 text-gray-200': checked
                        })}
                      >
                        {item?.name}
                      </div>
                    );
                  }}
                />
              </form>
            );
          }}
        </Formux>
      </div>
    </OutlinedBox>
  );
};
