import { Shimmer } from '@components/shimmer';

import { useBreakpoints } from '@hooks/useBreakpoints';

import { TableRow } from './table-row';

import { range } from '@utils/general';

export interface SkeletonBodyProps {
  headsAmount: number;
}

export const SkeletonBody = ({ headsAmount }: SkeletonBodyProps) => {
  const breakpoints = useBreakpoints();

  if (breakpoints.xs) {
    return (
      <tbody>
        {range(3).map((index) => {
          const nodes = [
            <div key={index} className="flex flex-col gap-3 w-full">
              <Shimmer className="w-full h-8" />
              <Shimmer className="w-4/12 h-8" />
              <Shimmer className="w-10/12 h-8" />
              <Shimmer className="w-3/12 h-8" />
              <Shimmer className="w-4/12 h-8" />
              <Shimmer className="w-2/3 h-8" />
            </div>
          ];

          return <TableRow key={index} index={index} nodes={nodes} />;
        })}
      </tbody>
    );
  }
  return (
    <tbody>
      {range(3).map((index) => {
        const nodes = range(headsAmount).map((index) => (
          <div key={index} className="flex flex-col gap-3 w-full">
            <Shimmer className="w-52 h-5" />
            <Shimmer className="w-20 h-5" />
            <Shimmer className="w-40 h-5" />
          </div>
        ));

        return <TableRow key={index} index={index} nodes={nodes} />;
      })}
    </tbody>
  );
};
